export type Wallet = {
  address: string;
  privateKey: string;
  phrase: string;
};

export type Balance = {
  balance: any;
  address: any;
  symbol: any;
  decimals: any;
  isNative: boolean;
  iconUrl: string;
  isLocked: boolean;
};

// Mainnet
// MTZ
export const TOKEN_USDT_ADDRESS = "0x398dcA951cD4fc18264d995DCD171aa5dEbDa129";
export const TOKEN_ADDRESS = "0x978C25c94Ea2cF39729BeE21D041b23f69e972Ac";
export const RPC_URL = "https://rpc.coinex.net";
export const EXPLORER_URL = "https://www.coinex.net/tx/";
export const SWAP_ROUTER_ADDRESS = "0x5eD3C9089Ed0355bc77CF439Dc2eD28c4054C8c4"; // Cập nhật địa chỉ Router trên CoinEx
export const CET_ADDRESS = "0x0000000000000000000000000000000000000000";

export const minABI = [
  {
    constant: true,
    inputs: [{ name: "_owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "balance", type: "uint256" }],
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "decimals",
    outputs: [{ name: "", type: "uint8" }],
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "symbol",
    outputs: [{ name: "", type: "string" }],
    type: "function",
  },
];
export const ERC20_ABI = [
  "function transfer(address to, uint256 amount) returns (bool)",
  "function balanceOf(address account) view returns (uint256)",
  "function decimals() view returns (uint8)",
  "function allowance(address owner, address spender) view returns (uint256)",
  "function approve(address spender, uint256 amount) returns (bool)",
  "function symbol() view returns (string)",
];

export interface SwapEstimation {
  init_price: string;
  path: {
    market_contract: string;
    money_contract: string;
    money_logo_url: string;
    money_symbol: string;
    stock_contract: string;
    stock_logo_url: string;
    stock_symbol: string;
  }[];
  from_contract: string;
  to_contract: string;
  to_amount: string;
  from_amount: string;
}

export interface SwapResult {
  amountIn: bigint;
  amountOut: bigint;
  tokenInAddress: string;
  tokenOutAddress: string;
}
