import React from "react";
import { ITEM_USERS_REFERRED } from "../utils/interface.global";

interface InviteFriendItemProps {
  item: ITEM_USERS_REFERRED;
  id: number;
}

function InviteFriendItem({ item, id }: InviteFriendItemProps) {
  return (
    <div className="flex items-center px-4 py-3 border-b border-gray-100 hover:bg-gray-50 transition-colors">
      <div className="w-1/4 flex items-center">
        <span className="text-sm font-medium text-gray-700">{id}</span>
      </div>
      <div className="w-3/4 flex items-center">
        <p className="text-sm font-medium text-gray-800 truncate">
          {item.firstName} {item.lastName}
        </p>
      </div>
    </div>
  );
}

export default InviteFriendItem;
