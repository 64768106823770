import React from "react";
import { useSelector } from "react-redux";

const ConfirmPopup: React.FC = () => {
    const { title, onConfirm, onCancel, isOpen ,header } = useSelector(
      (state: any) => state.confirm
    )
  if (!isOpen) return null;

  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-[10002]"></div>
      <div className="fixed inset-0 flex flex-col items-center justify-center z-[10003]">
        {header && header}
        <div className="flex flex-col justify-center px-4 py-8 text-base text-center rounded-2xl border-indigo-400 border-solid bg-slate-700 border-[6px] max-w-[363px]">
          {title}
        </div>
        <div className="flex gap-2.5 justify-center text-base leading-6 text-center text-white whitespace-nowrap w-full max-w-[363px] mt-3">
          <button
            onClick={onCancel}
            className="w-1/2 justify-center px-6 py-4 border-4 border-solid bg-zinc-500 border-zinc-400 rounded-[1000px]"
          >
            CANCEL
          </button>
          <button
            onClick={onConfirm}
            className="w-1/2 justify-center px-6 py-4 bg-lime-700 border-4 border-lime-500 border-solid rounded-[1000px]"
          >
            OK
          </button>
        </div>
      </div>
    </>
  );
};

export default ConfirmPopup;
