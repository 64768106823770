import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataContext from "../context/DataContext";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../redux/slices/userSlices";
import InviteFriendItem from "../components/InviteFriendItem";
import { triggerHapticFeedback } from "../utils/utils";
import { useToast } from "../components/hooks/use-toast";
import { Copy, Share2 } from "lucide-react";
import { fetchLeaderboardData, fetchReferralData } from "../auth/telegramAuth";
import { RootState, useAppDispatch } from "../redux/store";
import { TELEGRAM_BOT_URL } from "../components/constants/constant";
import {
  ChevronFirst,
  ChevronLast,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";
import Leaderboard from "../components/Leaderboard";
import { Button } from "../components/ui/button";
import {
  fetchUser,
  upgradeToUnlimitedReferral,
} from "../redux/slices/metazGameSlice";
import BlockchainInteraction from "../components/LoadingScreen";

function InviteFriend() {
  const navigate = useNavigate();
  const dataUser = useSelector(getUserData);
  const { wallet, setButtonBackTele } = useContext(DataContext)!;
  const [currentList, setCurrentList] = useState<any>([]);
  const [totalInvites, setTotalInvites] = useState(0);
  const [totalRewards, setTotalRewards] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [activeTab, setActiveTab] = useState("invites");
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [hasUnlimitedReferrals, setHasUnlimitedReferrals] = useState(false);
  const { user } = useSelector((state: RootState) => state.metaZGame);
  const dispatch = useAppDispatch();

  const [interactionState, setInteractionState] = useState<
    "idle" | "loading" | "success" | "failure"
  >("idle");
  const [interactionProgress, setInteractionProgress] = useState(0);
  const [errorMess, setErrorMess] = useState("");

  const jwt = useSelector((state: RootState) => state.jwt.token);

  const { toast } = useToast();

  useEffect(() => {
    if (!dataUser) {
      navigate("/");
    }

    setButtonBackTele(true, onCallBackButtonBack);

    fetchReferrals();
    fetchLeaderboard();
  }, [dataUser, currentPage, jwt]);

  useEffect(() => {
    if (!user) {
      dispatch(fetchUser());
    }
    setHasUnlimitedReferrals(user?.unlimitedReferrals || false);
  }, [dispatch, user]);

  const fetchLeaderboard = async () => {
    try {
      if (!jwt) {
        throw new Error("No token available");
      }

      const data = await fetchLeaderboardData(jwt);
      setLeaderboardData(data.topReferralPoints);
    } catch (error: any) {
      console.error("Error fetching leaderboard:", error);
      toast({
        description: "Failed to fetch leaderboard. Please try again.",
        variant: "destructive",
      });
    }
  };

  const fetchReferrals = async () => {
    try {
      if (!jwt) {
        throw new Error("No token available");
      }

      const data = await fetchReferralData(jwt, currentPage);

      setCurrentList(data.referrals);
      setTotalInvites(data.totalCount);
      setTotalPages(data.totalPages);

      // Calculate total rewards = total Count * 0.1 MTZ
      const totalRewards = data.totalCount * 0.1;
      setTotalRewards(totalRewards);
    } catch (error: any) {
      console.error("Error fetching referrals:", error);
      toast({
        description: "Failed to fetch referrals. Please try again.",
        variant: "destructive",
      });
      if (error.message === "Unauthorized") {
        // Handle unauthorized error, perhaps by redirecting to login or refreshing the token
      }
    }
  };

  const onCallBackButtonBack = () => {
    navigate("/");
  };

  const getInviteLink = () => {
    const telegramId = window.Telegram.WebApp.initDataUnsafe.user.id;
    return `${TELEGRAM_BOT_URL()}ref_${telegramId}`;
  };

  const onCopyLink = () => {
    const inviteLink = getInviteLink();

    navigator.clipboard
      .writeText(inviteLink)
      .then(() => {
        toast({
          description: "Link copied to clipboard",
        });
        triggerHapticFeedback("medium");
      })
      .catch((err) => {
        console.error("Failed to copy link:", err);
      });
  };

  const onShareLink = () => {
    const inviteLink = getInviteLink();
    const shareMessage = `Join MetaZ to start farming MTZ & CET, earning unmatchable rewards ${inviteLink}`;

    if (window.Telegram && window.Telegram.WebApp) {
      const encodedMessage = encodeURIComponent(shareMessage);
      const shareUrl = `https://t.me/share/url?url=${encodedMessage}`;

      window.Telegram.WebApp.openTelegramLink(shareUrl);
      triggerHapticFeedback("medium");
    } else {
      console.error("Telegram WebApp is not available");
    }
  };

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleUpgrade = async () => {
    triggerHapticFeedback("medium");
    setInteractionState("loading");
    setInteractionProgress(0);

    try {
      for (let i = 0; i <= 50; i += 10) {
        setInteractionProgress(i);
        await new Promise((resolve) => setTimeout(resolve, 200));
      }

      await dispatch(upgradeToUnlimitedReferral()).unwrap();

      for (let i = 60; i <= 100; i += 10) {
        setInteractionProgress(i);
        await new Promise((resolve) => setTimeout(resolve, 200));
      }

      await dispatch(fetchUser());

      setInteractionState("success");
      setHasUnlimitedReferrals(true);
      toast({
        description: "Successfully upgraded to unlimited referrals!",
        duration: 3000,
      });
    } catch (error: any) {
      console.error("Error during upgrade process:", error);
      setErrorMess(error.message);
      setInteractionState("failure");
      toast({
        description: "Failed to upgrade. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="flex flex-col pt-4 pb-10 mx-auto w-full max-w-[480px] px-4">
      <h1 className="text-3xl font-black text-zinc-800 text-center mb-6">
        Invite Friends 🤝
      </h1>
      <div className="flex justify-around mb-8 bg-white shadow-md rounded-lg p-4">
        <div className="text-center">
          <p className="text-sm font-medium text-stone-500">Total Invites</p>
          <p className="text-2xl font-semibold text-zinc-800 mt-1">
            {totalInvites} / {hasUnlimitedReferrals ? "∞" : "50"} 👥
          </p>
        </div>
        <div className="text-center">
          <p className="text-sm font-medium text-stone-500">Total Rewards</p>
          <p className="text-2xl font-semibold text-zinc-800 mt-1">
            {totalRewards.toFixed(4)} MTZ 🎁
          </p>
        </div>
      </div>
      {!hasUnlimitedReferrals && (
        <Button
          onClick={handleUpgrade}
          className="mb-6 bg-[#fdb357] hover:bg-[#fda234] text-white"
        >
          Upgrade to Unlimited Referrals 🚀
        </Button>
      )}
      <div className="flex gap-4 mb-6">
        <button
          onClick={onCopyLink}
          className="flex-1 flex items-center justify-center bg-[#fdb357] text-white py-3 px-4 rounded-lg hover:bg-[#fda234] transition-colors text-sm sm:text-base"
        >
          <Copy size={18} className="mr-2" />
          Copy Link 📋
        </button>
        <button
          onClick={onShareLink}
          className="flex-1 flex items-center justify-center bg-white text-[#fdb357] border border-[#fdb357] py-3 px-4 rounded-lg hover:bg-[#fff8ef] transition-colors text-sm sm:text-base"
        >
          <Share2 size={18} className="mr-2" />
          Share 📢
        </button>
      </div>
      <div className="flex mb-4">
        <button
          className={`flex-1 py-2 px-4 text-center ${
            activeTab === "invites"
              ? "bg-[#fdb357] text-white"
              : "bg-white text-[#fdb357] border border-[#fdb357]"
          } rounded-l-lg transition-colors`}
          onClick={() => setActiveTab("invites")}
        >
          My Invites 👥
        </button>
        <button
          className={`flex-1 py-2 px-4 text-center ${
            activeTab === "leaderboard"
              ? "bg-[#fdb357] text-white"
              : "bg-white text-[#fdb357] border border-[#fdb357]"
          } rounded-r-lg transition-colors`}
          onClick={() => setActiveTab("leaderboard")}
        >
          Leaderboard 🏆
        </button>
      </div>
      {activeTab === "invites" ? (
        <>
          <h2 className="text-lg font-semibold text-zinc-800 mb-4">
            Invitation History 📜
          </h2>
          <div className="bg-white shadow-md rounded-lg overflow-hidden mb-6">
            <div className="px-4 py-3 border-b border-gray-200">
              <div className="flex justify-between text-sm font-medium text-gray-500">
                <div className="w-1/4">No. 🔢</div>
                <div className="w-3/4">Friend's Name 👤</div>
              </div>
            </div>
            <div className="max-h-[calc(100vh-400px)] overflow-y-auto">
              {currentList && currentList.length > 0 ? (
                currentList.map((item: any, index: number) => (
                  <InviteFriendItem
                    key={item.telegramId}
                    item={item}
                    id={(currentPage - 1) * 10 + index + 1}
                  />
                ))
              ) : (
                <p className="text-center py-4 text-gray-500">
                  No invites yet 🕵️‍♂️
                </p>
              )}
            </div>
          </div>
          <div className="flex justify-center items-center gap-2">
            <button
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
              className="p-2 rounded-full border border-gray-300 disabled:opacity-50"
              aria-label="First page"
            >
              <ChevronFirst size={20} />
            </button>
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="p-2 rounded-full border border-gray-300 disabled:opacity-50"
              aria-label="Previous page"
            >
              <ChevronLeft size={20} />
            </button>
            <span className="mx-2 text-sm font-medium">
              Page {currentPage} of {totalPages} 📄
            </span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="p-2 rounded-full border border-gray-300 disabled:opacity-50"
              aria-label="Next page"
            >
              <ChevronRight size={20} />
            </button>
            <button
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
              className="p-2 rounded-full border border-gray-300 disabled:opacity-50"
              aria-label="Last page"
            >
              <ChevronLast size={20} />
            </button>
          </div>
          <BlockchainInteraction
            state={interactionState}
            progress={interactionProgress}
            errorMesssage={errorMess}
            onClose={() => {
              setInteractionState("idle");
              setInteractionProgress(0);
            }}
          />
        </>
      ) : (
        <Leaderboard data={leaderboardData} />
      )}
    </div>
  );
}

export default InviteFriend;
