import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { thunk } from "redux-thunk";
import userSlices from "./slices/userSlices";
import popupSlices from "./slices/popupSlices";
import notificationSlices from "./slices/notificationSlices";
import confirmSlices from "./slices/confirmSlices";
import jwtSlice from "./slices/jwtSlice";
import metazGameSlice from "./slices/metazGameSlice";
import { useDispatch } from "react-redux";
const rootPersistConfig = {
  key: "metaz-game",
  storage: storage,
  blacklist: ["error_send_data"],
};

const rootReducer = combineReducers({
  user: persistReducer(rootPersistConfig, userSlices),
  popup: popupSlices,
  notification: notificationSlices,
  confirm: confirmSlices,
  jwt: jwtSlice,
  metaZGame: metazGameSlice,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(thunk),
  devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
export const useAppDispatch = () => useDispatch<AppDispatch>();
