import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import React from "react";

export interface NotificationState {
  isOpen: boolean;
  content: React.ReactNode | null;
  title: string;
  type?: ETypeNotification;
  onClickConfirm?: () => void;
}

export enum ETypeNotification {
  SUCCESS = "success",
  FAILURE = "failure",
  CONFIRM = "confirm",
}

// Define the initial state
const initialState: NotificationState = {
  isOpen: false,
  content: null,
  title: "",
  type: ETypeNotification.SUCCESS,
  onClickConfirm: () => {},
};

const notificationStateSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    openNotification: (
      state: any,
      action: PayloadAction<{
        content: React.ReactNode;
        title: string;
        type?: string;
        onClickConfirm?: () => void;
      }>
    ) => {
      state.isOpen = !state.isOpen;
      state.content = action.payload.content;
      state.title = action.payload.title;
      state.type = action.payload.type;
      state.onClickConfirm = action.payload.onClickConfirm;
    },
    destroyNotification: (state: any) => {
      state.isOpen = false;
      state.content = null;
      state.title = "";
      state.type = ETypeNotification.SUCCESS;
    },
  },
});

//Reducers
export const { openNotification, destroyNotification } =
  notificationStateSlice.actions;

// reducer
export default notificationStateSlice.reducer;
