import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface JwtState {
  token: string | null;
}

const initialState: JwtState = {
  token: null,
};

export const jwtSlice = createSlice({
  name: "jwt",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    clearToken: (state) => {
      state.token = null;
    },
  },
});

export const { setToken, clearToken } = jwtSlice.actions;

// Selector
export const selectJwtToken = (state: RootState) => state.jwt.token;

export default jwtSlice.reducer;
