import { useState } from "react";
import { useParams } from "react-router-dom";
import { decryptData } from "../utils/utils";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";

interface SecretKeyData {
  privateKey: string;
  timestamp: number;
  version: string;
}

function SecretKeyPage() {
  const { encryptedData } = useParams();
  const [password, setPassword] = useState("");
  const [secretKeyData, setSecretKeyData] = useState<SecretKeyData>();
  const [error, setError] = useState("");

  const handleDecrypt = async () => {
    try {
      if (!encryptedData) {
        setError("No encrypted data found in URL");
        return;
      }

      const decodedData = decodeURIComponent(encryptedData);
      console.log("Decoded data:", decodedData);
      const decryptedData = decryptData(decodedData, password);
      const parsedData = JSON.parse(decryptedData);

      console.log("Parsed data:", parsedData);

      if (
        !parsedData.privateKey ||
        !parsedData.timestamp ||
        !parsedData.version
      ) {
        throw new Error("Invalid data format");
      }

      setSecretKeyData(parsedData);
      setError("");
    } catch (err) {
      console.error("Decryption failed:", err);
      setError("Incorrect password or invalid data");
    }
  };

  return (
    <div className="flex flex-col p-4 mx-auto w-full text-base shadow-sm max-w-[480px] text-zinc-800">
      <h1 className="text-3xl font-black leading-10 mb-6">Your Secret Key</h1>
      {!secretKeyData ? (
        <>
          <Input
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="mb-4"
          />
          <Button onClick={handleDecrypt}>View Secret Key</Button>
          {error && <p className="text-red-500 mt-2">{error}</p>}
        </>
      ) : (
        <div className="bg-gray-100 p-4 rounded-md">
          <p className="font-bold mb-2">Your Secret Key:</p>
          <p className="break-all">{secretKeyData.privateKey}</p>
          <p className="mt-4 text-sm text-gray-600">
            Created: {new Date(secretKeyData.timestamp).toLocaleString()}
          </p>
          <p className="text-sm text-gray-600">
            Version: {secretKeyData.version}
          </p>
        </div>
      )}
    </div>
  );
}

export default SecretKeyPage;
