import { useCallback, useEffect, useState } from "react";
import "./App.css";
import Home from "./pages/Home";
import ProtectedRouter from "./auth/ProtectedRouter";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import DataContext from "./context/DataContext";
import Transfer from "./pages/Transfer";
import ProtectedLogin from "./auth/ProtectedLogin";
import Login from "./pages/Login";
import { Balance, Wallet } from "./constants/constants";
import { getUserData, updatePrivateKey } from "./redux/slices/userSlices";
import { useDispatch, useSelector } from "react-redux";
import { decryptWallet, getBalanceWallet } from "./utils/utils";
import Settings from "./pages/Settings";
import Backups from "./pages/Backups";
import InviteFriend from "./pages/InviteFriend";
import Swap from "./pages/Swap";
import GameHome from "./pages/GameHome";
import Loading from "./components/Loading";
import GameMission from "./pages/GameMission";
import GameMissionDetail from "./pages/GameMissionDetail";
import logo from "./assets/logo.png";
import { AppDispatch, RootState, store } from "./redux/store";
import {
  fetchMTZPendingRewards,
  initializeTelegramAuth,
} from "./auth/telegramAuth";
import { METAZ_TOKEN_ADDRESS } from "./components/constants/constant";
import SecretKeyPage from "./pages/SecretKey";
import { fetchMTZLockedBalance } from "./redux/slices/metazGameSlice";

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
}

declare global {
  interface Window {
    Telegram: {
      WebApp: {
        initData: any;
        HapticFeedback: {
          impactOccurred: (style: string) => void;
        };
        expand: () => void;
        ready: () => void;
        close: () => void;
        BackButton: {
          show: () => void;
          hide: () => void;
        };
        showScanQrPopup: (params: { text?: string }) => void;
        closeScanQrPopup: () => void;
        onEvent: (
          eventType: string,
          eventHandler: (event: any) => void
        ) => void;
        offEvent: (
          eventType: string,
          eventHandler: (event: any) => void
        ) => void;
        offClick: (event: string, callback: () => void) => void;
        initDataUnsafe: any;
        sendData: (data: any) => void;
        openTelegramLink: (link: string) => void;
        isVerticalSwipesEnabled: () => boolean;
        enableVerticalSwipes: () => void;
        disableVerticalSwipes: () => void;
        openLink: (link: string) => void;
        showAlert: (text: string) => void;
      };
    };
  }
}

const tele = window.Telegram.WebApp ?? null;

const router = createBrowserRouter([
  {
    path: "/",
    children: [
      { element: <Navigate to="Login" />, index: true },
      {
        path: "Login",
        element: <ProtectedLogin props={<Login />} type={""} key={""} />,
      },
      { path: "secret-key/:encryptedData", element: <SecretKeyPage /> },
    ],
  },
  {
    path: "/game/*",
    element: <ProtectedRouter />,
    children: [
      { path: "", element: <Home /> },
      { path: "settings", element: <Settings /> },
      { path: "backups", element: <Backups /> },
      { path: "invite-friend", element: <InviteFriend /> },
      { path: "swap", element: <Swap /> },
      { path: "transfer", element: <Transfer /> },
      { path: "play", element: <GameHome /> },
      { path: "mission", element: <GameMission /> },
      { path: "mission/:id", element: <GameMissionDetail /> },
    ],
  },
]);

function App() {
  const [userTele, setUserTele] = useState(null);
  const [wallet, setWallet] = useState<Wallet | null>(null);
  const [balanceToken, setBalanceToken] = useState<Balance[]>([]);
  const [descriptionLoading, setDescriptionLoading] =
    useState<string>("Loading...");
  const [isShowLoading, setIsShowLoading] = useState<boolean>(false);
  const dataUser = useSelector(getUserData);
  const dispatch = useDispatch<AppDispatch>();
  const lockedCETBalance = useSelector(
    (state: RootState) => state.metaZGame.user?.cetBalance
  );
  const [lockedMTZBalance, setLockedMTZBalance] = useState("0");
  const [isInitDataLoaded, setIsInitDataLoaded] = useState<boolean>(false);
  const [loadingProgress, setLoadingProgress] = useState(0);

  const reduxLockedMTZBalance = useSelector(
    (state: RootState) => state.metaZGame.lockedMTZBalance
  );

  const jwt = useSelector((state: RootState) => state.jwt.token);

  useEffect(() => {
    tele.ready();

    const checkInitData = () => {
      const initData = tele.initDataUnsafe;
      const initData1 = tele.initData;
      if (initData) {
        setUserTele(initData);
        tele.expand();
        tele.disableVerticalSwipes();

        initializeTelegramAuth(initData, initData1, dispatch, () =>
          store.getState()
        )
          .then((token) => {
            if (token) {
              console.log("Successfully authenticated with Telegram");
            } else {
              setUserTele(null);
            }
            setIsInitDataLoaded(true);
          })
          .catch((error) => {
            setUserTele(null);
            setIsInitDataLoaded(true);
          });
      } else {
        if (process.env.NODE_ENV !== "production") {
          const devInitData: any = {
            query_id: "AAH6jMNNAAAAAPqMw02LAE24",
            user: {
              id: 1304661242,
              first_name: "Phong",
              last_name: "Tran",
              username: "nhiepphong",
              language_code: "en",
              allows_write_to_pm: true,
            },
            auth_date: "1715263464",
            hash: "792a2fb9119b96de386587dd6972ca4b0d4b03705a46ef81cc32d4ca48831418",
          };
          setUserTele(devInitData);
          setIsInitDataLoaded(true);
        } else {
          // In production, if initData is not available, we'll check again after a short delay
          setTimeout(checkInitData, 100);
        }
      }
    };

    checkInitData();

    // Simulating loading progress
    const interval = setInterval(() => {
      setLoadingProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prevProgress + 10;
      });
    }, 200);

    return () => clearInterval(interval);
  }, [dispatch]);

  useEffect(() => {
    if (dataUser != null) {
      const w = decryptWallet(dataUser.wallet, dataUser.telegram.id.toString());
      console.log("wallet", w);
      if (w != null) {
        setWallet(w);
        dispatch(updatePrivateKey(w.privateKey));
      }
    }
  }, [dataUser, dispatch]);

  // fetch locked MTZ balance from API
  useEffect(() => {
    if (wallet) {
      // fetch locked MTZ balance
      if (!jwt) {
        return;
      }

      dispatch(fetchMTZLockedBalance());
    }
  }, [wallet, jwt, dispatch]);

  const getBalanceToken = useCallback(async () => {
    if (wallet) {
      const balance: any = await getBalanceWallet(
        wallet.address,
        lockedCETBalance ?? "0",
        reduxLockedMTZBalance ?? "0",
        [METAZ_TOKEN_ADDRESS()]
      );
      setBalanceToken(balance);
    }
  }, [wallet, lockedCETBalance, reduxLockedMTZBalance]);

  useEffect(() => {
    if (wallet) {
      getBalanceToken();
    }
  }, [getBalanceToken, wallet]);

  const setButtonBackTele = (status: boolean, callback: any) => {
    try {
      if (tele) {
        if (status) {
          tele.BackButton.show();
          tele.onEvent("backButtonClicked", callback);
        } else {
          tele.BackButton.hide();
          tele.offClick("backButtonClicked", callback);
        }
      }
    } catch (error) {}
  };

  if (!isInitDataLoaded) {
    return (
      <div className="min-h-screen bg-gray-100 flex flex-col">
        <div
          className="h-1 bg-blue-500 transition-all duration-300 ease-out"
          style={{ width: `${loadingProgress}%` }}
        ></div>
        <div className="flex-1 flex items-center justify-center">
          <div className="text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500 mx-auto"></div>
            <div className="mt-4 text-gray-700 font-medium">
              Waiting for Telegram data...
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <DataContext.Provider
      value={{
        userTele,
        setUserTele,
        wallet,
        setWallet,
        balanceToken,
        setBalanceToken,
        setButtonBackTele,
        setDescriptionLoading,
        setIsShowLoading,
        getBalanceToken,
      }}
    >
      {userTele ? (
        <>
          <RouterProvider router={router} />
          <Loading description={descriptionLoading} isShow={isShowLoading} />
        </>
      ) : (
        <>
          <div
            id="container"
            className="w-full max-w-[480px] mx-auto min-h-screen flex flex-col justify-start bg-white"
          >
            <div className="relative flex-1 flex">
              <div className="flex flex-col justify-center items-center px-4 py-20 mx-auto w-full h-full min-h-screen text-base text-center max-w-[480px] bg-white bg-opacity-80">
                <img
                  loading="lazy"
                  src={logo}
                  alt="Logo"
                  className="w-full max-w-sm aspect-square"
                />
                <div className="flex flex-col justify-center self-stretch px-4 w-full">
                  <div className="text-sm font-normal">
                    Unable to connect to Telegram
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </DataContext.Provider>
  );
}

export default App;
