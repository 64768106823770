import { useEffect, useState } from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../components/ui/alert-dialog";
import { Button } from "../components/ui/button";
import { Checkbox } from "../components/ui/checkbox";
import { HelpCircle, RefreshCw } from "lucide-react";

interface LeaderboardItem {
  rank: number;
  name: string;
  points: number;
  estimatedPrize: number;
}

interface LeaderboardProps {
  data: any[];
}

function Leaderboard({ data }: LeaderboardProps) {
  const [processedData, setProcessedData] = useState<LeaderboardItem[]>([]);
  const [open, setOpen] = useState(false);
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);

  useEffect(() => {
    const processedData = data.map((item, index) => {
      const rank = index + 1;
      let estimatedPrize = 0;

      if (rank === 1) estimatedPrize = 840;
      else if (rank === 2) estimatedPrize = 600;
      else if (rank === 3) estimatedPrize = 400;
      else if (rank >= 4 && rank <= 10) estimatedPrize = 80;
      else if (rank >= 11 && rank <= 50) estimatedPrize = 40;
      else if (rank >= 51 && rank <= 100) estimatedPrize = 20;

      return {
        name:
          item.telegramData.user.first_name +
          " " +
          item.telegramData.user.last_name,
        points: item.referralPoints ?? 0,
        rank: rank,
        estimatedPrize: estimatedPrize
      };
    });
    setProcessedData(processedData);

    // Check if it's the first visit
    const hasVisited = localStorage.getItem("leaderboardHelpShown");
    if (!hasVisited) {
      setOpen(true);
    }
  }, [data]);

  const handleCloseHelp = () => {
    setOpen(false);
    if (doNotShowAgain) {
      localStorage.setItem("leaderboardHelpShown", "true");
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
      <div className="p-4 border-b border-gray-200">
        <div className="flex justify-between items-center mb-2">
          <h2 className="text-lg font-semibold text-zinc-800">
            Top Inviters 🏆
          </h2>
          <AlertDialog open={open} onOpenChange={setOpen}>
            <AlertDialogTrigger asChild>
              <Button variant="outline" size="icon">
                <HelpCircle className="h-4 w-4" />
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent className="max-h-[80vh] flex flex-col">
              <AlertDialogHeader>
                <AlertDialogTitle>How the Leaderboard Works</AlertDialogTitle>
              </AlertDialogHeader>
              <AlertDialogDescription className="overflow-y-auto flex-grow">
                <div className="space-y-4">
                  <p>
                    Invite friends to upgrade and share 5,000 USDT per month.
                    (Calculated according to the number of MTZ that friends used
                    to upgrade)
                  </p>
                  <div>
                    <h3 className="font-semibold mb-2">
                      Rankings and Rewards:
                    </h3>
                    <ul className="list-disc list-inside space-y-1">
                      <li>
                        <strong>TOP 1:</strong> 840 USDT
                      </li>
                      <li>
                        <strong>TOP 2:</strong> 600 USDT
                      </li>
                      <li>
                        <strong>TOP 3:</strong> 400 USDT
                      </li>
                      <li>
                        <strong>TOP 4-10:</strong> 80 USDT each (560 USDT total)
                      </li>
                      <li>
                        <strong>TOP 11-50:</strong> 40 USDT each (1600 USDT
                        total)
                      </li>
                      <li>
                        <strong>TOP 51-100:</strong> 20 USDT each (1000 USDT
                        total)
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3 className="font-semibold mb-2">Key Points:</h3>
                    <ul className="list-disc list-inside space-y-1">
                      <li>
                      Ranking is based on upgrade points. Each successfully invited user is counted 1 point, each MTZ (invited user) used to upgrade is counted 1 point.
                      </li>
                      <li>The leaderboard updates every 30 minutes</li>
                      <li>
                        Top 3 ranks are awarded special medals: 🥇, 🥈, 🥉
                      </li>
                      <li>
                        Tie-breakers are resolved based on who reached the score
                        first
                      </li>
                    </ul>
                  </div>
                  <p>Keep inviting to climb the ranks and earn your rewards!</p>
                </div>
              </AlertDialogDescription>
              <AlertDialogFooter className="flex-col items-stretch sm:flex-row sm:justify-end sm:space-x-2">
                <div className="flex items-center space-x-2 mb-4 sm:mb-0">
                  <Checkbox
                    id="doNotShowAgain"
                    checked={doNotShowAgain}
                    onCheckedChange={(checked) =>
                      setDoNotShowAgain(checked as boolean)
                    }
                  />
                  <label
                    htmlFor="doNotShowAgain"
                    className="text-sm text-gray-500 cursor-pointer"
                  >
                    Don't show this again
                  </label>
                </div>
                <AlertDialogAction onClick={handleCloseHelp}>
                  Got it, thanks!
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>
        <div className="flex items-center text-xs text-gray-500">
          <RefreshCw className="h-3 w-3 mr-1" />
          Updates every 30 minutes
        </div>
      </div>
      <div className="px-4 py-3 border-b border-gray-200">
        <div className="flex justify-between text-sm font-medium text-gray-500">
          <div className="w-1/6">Rank</div>
          <div className="w-2/6">Name</div>
          <div className="w-1/6">Points</div>
          <div className="w-2/6">Estimated Prize</div>
        </div>
      </div>
      <div className="max-h-[calc(100vh-400px)] overflow-y-auto">
        {processedData.length > 0 ? (
          processedData.map((item, index) => (
            <div
              key={index}
              className="flex items-center px-4 py-3 border-b border-gray-100 hover:bg-gray-50 transition-colors"
            >
              <div className="w-1/6 flex items-center">
                <span className="text-sm font-medium text-gray-700">
                  {item.rank === 1
                    ? "🥇"
                    : item.rank === 2
                    ? "🥈"
                    : item.rank === 3
                    ? "🥉"
                    : `#${item.rank}`}
                </span>
              </div>
              <div className="w-2/6 flex items-center">
                <p className="text-sm font-medium text-gray-800 truncate">
                  👤 {item.name}
                </p>
              </div>
              <div className="w-1/6 flex items-center">
                <p className="text-sm font-medium text-gray-700">
                  {item.points} 🪙
                </p>
              </div>
              <div className="w-2/6 flex items-center">
                <p className="text-sm font-medium text-gray-700">
                  {item.estimatedPrize > 0 ? `${item.estimatedPrize} USDT` : '-'}
                </p>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center py-4 text-gray-500">No data available 🕵️‍♂️</p>
        )}
      </div>
    </div>
  );
}

export default Leaderboard;