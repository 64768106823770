import Button from "./Button";
import icon_transfer_true from "../assets/icon-transfer-true.png";
import icon_transfer_false from "../assets/icon-transfer-false.png";
import { EXPLORER_URL } from "../constants/constants";

type Props = {
  result: {
    status: boolean;
    message: string;
    txHash: string;
  };
  onClose: () => void;
};

const SwapResult = ({ result, onClose }: Props) => {
  const onGoHome = () => {
    onClose();
  };

  const openExplorer = () => {
    window.open(`${EXPLORER_URL}${result.txHash}`, "_blank");
  };

  return (
    <div className="flex flex-col justify-center py-20 mx-auto w-full text-base font-semibold leading-6 text-center bg-white max-w-[480px] text-zinc-800">
      <div className="flex flex-col px-3 py-4 mt-28 w-full items-center">
        {result.status === true ? (
          <>
            <img
              loading="lazy"
              src={icon_transfer_true}
              className="self-center max-w-full aspect-square w-[120px]"
            />
            <div className="mt-4 text-2xl leading-6 text-lime-400 mb-4">
              {result.message}
            </div>
          </>
        ) : (
          <>
            <img
              loading="lazy"
              src={icon_transfer_false}
              className="self-center max-w-full aspect-square w-[120px]"
            />
            <div className="mt-4 text-2xl leading-6 text-rose-600">
              {result.message}
            </div>
          </>
        )}
        <div className="mt-4 text-teal-500">
          <button onClick={openExplorer}>View transaction details</button>
        </div>
        <Button
          isLoading={false}
          className=""
          label="Return to Home"
          handleClick={onGoHome}
        />
      </div>
    </div>
  );
};

export default SwapResult;
