import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import React from "react";

export interface ConfirmState {
  title: string | React.ReactNode;
  onConfirm: () => void;
  onCancel: () => void;
  isOpen: boolean;
  header?: React.ReactNode;
}

// Define the initial state
const initialState: ConfirmState = {
  title: "",
  onConfirm: () => {},
  onCancel: () => {},
  isOpen: false,
  header: undefined,
};

const confirmSlice = createSlice({
  name: "confirm",
  initialState,
  reducers: {
    openConfirmPopup: (state, action: PayloadAction<ConfirmState>) => {
      state.isOpen = true;
      state.title = action.payload.title;
      state.onConfirm = action.payload.onConfirm;
      state.onCancel = action.payload.onCancel;
      state.header = action.payload.header;
    },
    closeConfirmPopup: (state) => {
      state.isOpen = false;
    },
  },
});

//Reducers
export const { openConfirmPopup, closeConfirmPopup } =
  confirmSlice.actions;

// reducer
export default confirmSlice.reducer;
