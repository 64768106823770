import React, { ChangeEvent, KeyboardEvent } from "react";

type Props = {
  placeholder?: string;
  value: string;
  className: string;
  onChange: (value: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  type?: string;
};

const CustomInput: React.FC<Props> = ({
  className,
  placeholder,
  value,
  onChange,
  onBlur,
  onKeyDown,
  type = "text",
}) => {
  return (
    <div className="w-full">
      <input
        type={type}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        contentEditable="true"
        className={`w-full break-all outline-none text-zinc-800 ${className}`}
        placeholder={placeholder}
      />
    </div>
  );
};

export default CustomInput;
