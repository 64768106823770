import { useEffect, useState } from "react";
import icon_sui from "../assets/icon-sui.png";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";

type Props = {
  description: string | "Loading...";
  isShow: boolean | false;
};
const Loading = ({ description, isShow }: Props) => {
  const [percent, setPercent] = useState(0);
  const [isShowLoading, setIsShowLoading] = useState(false);
  useEffect(() => {
    if (isShow) {
      const interval = setInterval(() => {
        setPercent((old) => {
          if (old < 100) {
            return old + 1;
          }
          setIsShowLoading(false);
          clearInterval(interval);
          return 0;
        });
      }, 50);
      return () => clearInterval(interval);
    }
  }, []);

  useEffect(() => {
    setIsShowLoading(isShow);
  }, [isShow]);
  return (
    <>
      {isShowLoading ? (
        <div className="fixed z-20 top-0 left-0 flex flex-col justify-center p-4 text-center bg-white h-screen w-full text-zinc-800">
          <div className="flex overflow-hidden relative flex-col items-center p-20 aspect-square">
            <CircularProgressbarWithChildren
              value={percent}
              styles={{
                path: {
                  stroke: "#9487f0",
                  strokeLinecap: "round",
                  transition: "stroke-dashoffset 0.5s ease 0s",
                },
                trail: {
                  stroke: "#f4f4f4",
                  strokeLinecap: "round",
                },
              }}
            >
              <div className="flex flex-col items-center px-16 py-20 w-full">
                <div className="mt-5 text-4xl font-bold">{percent}%</div>
                <div className="mb-5 text-base">{description}</div>
              </div>
            </CircularProgressbarWithChildren>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Loading;
