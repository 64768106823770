import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import { ArrowRight, Lock } from "lucide-react";
import {
  convertToBigIntUsingEthers,
  estimateSwapToken,
  formatBalance,
  formatDecimalString,
} from "../utils/utils";
import { Balance, TOKEN_USDT_ADDRESS } from "../constants/constants";

type Props = {
  data: Balance;
  onClaim?: () => void;
  onClaimMTZ?: () => void;
  countdown?: number;
};

const ITemToken = ({ data, onClaim, countdown, onClaimMTZ }: Props) => {
  const [price, setPrice] = useState(BigInt(0));
  const [timeLeft, setTimeLeft] = useState<string | null>(null);

  useEffect(() => {
    const getEstimateSwapToken = async () => {
      const amountIn = ethers.formatUnits(data.balance, data.decimals);
      const result = await estimateSwapToken(
        data.address,
        TOKEN_USDT_ADDRESS,
        amountIn
      );
      const amountOut = convertToBigIntUsingEthers(
        result.to_amount,
        data.decimals
      );
      setPrice(amountOut);
    };
    getEstimateSwapToken();
  }, [data.address, data.balance, data.decimals]);

  useEffect(() => {
    if (countdown) {
      const timer = setInterval(() => {
        const now = new Date().getTime();
        const distance = countdown - now;

        if (distance < 0) {
          clearInterval(timer);
          setTimeLeft(null);
        } else {
          const days = Math.floor(distance / (1000 * 60 * 60 * 24));
          const hours = Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor(
            (distance % (1000 * 60 * 60)) / (1000 * 60)
          );
          setTimeLeft(`${days}d ${hours}h ${minutes}m`);
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [countdown]);

  const renderClaimButton = () => {
    if (!data.isLocked) return null;

    if (countdown && timeLeft) {
      return (
        <button
          disabled
          className="ml-2 px-2 py-0.5 text-xs font-medium text-gray-500 bg-gray-200 rounded flex items-center gap-1"
        >
          {timeLeft}
        </button>
      );
    }

    return (
      <button
        onClick={data.symbol === "MTZ" ? onClaimMTZ : onClaim}
        className="ml-2 px-2 py-0.5 text-xs font-medium text-white bg-yellow-500 rounded flex items-center gap-1"
      >
        Claim <ArrowRight className="w-3 h-3" />
      </button>
    );
  };

  return (
    <div className="flex gap-5 justify-between py-4 w-full text-center whitespace-nowrap">
      <div className="flex gap-2">
        <div className="relative flex items-center justify-center w-10 h-10 bg-white rounded-full border border-[#252525]">
          <img
            loading="lazy"
            src={data.iconUrl ? data.iconUrl : "/api/placeholder/48/48"}
            alt={`${data.symbol} icon`}
            className="shrink-0 w-12 aspect-square"
          />
          {data.isLocked && (
            <Lock className="absolute -top-1 -right-1 w-4 h-4 text-gray-500" />
          )}
        </div>
        <div className="flex flex-col my-auto text-left">
          <div className="flex gap-1 text-base text-zinc-800">
            <div className="font-medium">
              {formatDecimalString(
                formatBalance(data.balance, data.decimals),
                4
              )}
            </div>
            <div className="font-semibold">{data.symbol}</div>
            {renderClaimButton()}
          </div>
        </div>
      </div>
      <div className="text-sm my-auto text-neutral-400 text-right">
        ~{formatDecimalString(formatBalance(price, data.decimals), 2)} USD
      </div>
    </div>
  );
};

export default ITemToken;
