import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import main_lv1 from "../assets/nft1.png";
import main_lv2 from "../assets/nft2.png";
import main_lv3 from "../assets/nft3.png";
import main_lv4 from "../assets/nft4.png";
import main_lv5 from "../assets/nft5.png";
import main_lv6 from "../assets/nft6.png";
import icon_METAZ_black from "../assets/metaz_logo.png";

const MAX_REWARD_LEVEL = 6;
const MAX_CET_CLAIMERS = 1000;

const rewardUpgradeCosts = [
  0,
  10 * 10 ** 18,
  20 * 10 ** 18,
  40 * 10 ** 18,
  100 * 10 ** 18,
  1000 * 10 ** 18,
];

const rewardAmounts = [
  1 * 10 ** 16,
  1 * 10 ** 16,
  1 * 10 ** 17,
  2 * 10 ** 17,
  3 * 10 ** 17,
  4 * 10 ** 17,
  10 * 10 ** 18, // CET amount for max level
];

interface PopupUpgradeCATProps {
  isShowPopup: boolean;
  onClose: () => void;
  currentRewardLevel: number;
  onUpgrade: () => void;
}

const PopupUpgradeCAT: React.FC<PopupUpgradeCATProps> = ({
  isShowPopup,
  onClose,
  currentRewardLevel,
  onUpgrade,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const cetClaimers =
    useSelector((state: RootState) => state.metaZGame.user?.cetClaimers) || 0;

  useEffect(() => {
    if (isShowPopup) {
      setIsVisible(true);
    } else {
      const timer = setTimeout(() => setIsVisible(false), 300); // Match this with the transition duration
      return () => clearTimeout(timer);
    }
  }, [isShowPopup]);

  const isMaxLevel = currentRewardLevel >= MAX_REWARD_LEVEL;
  const canUpgradeToLevel5 =
    currentRewardLevel === 5 && cetClaimers < MAX_CET_CLAIMERS;

  const upgradeCost = isMaxLevel ? 0 : rewardUpgradeCosts[currentRewardLevel];
  const formattedUpgradeCost = (upgradeCost / 10 ** 18).toString();

  const currentRewardAmount = rewardAmounts[currentRewardLevel];
  const nextRewardAmount = isMaxLevel
    ? currentRewardAmount
    : rewardAmounts[currentRewardLevel + 1];

  const progressPercentage = (currentRewardLevel / MAX_REWARD_LEVEL) * 100;
  const cetClaimerProgressPercentage = (cetClaimers / MAX_CET_CLAIMERS) * 100;

  const formatRewardAmount = (amount: number, level: number) => {
    const amountIn2Hours = amount / 10 ** 18;
    return `${amountIn2Hours.toFixed(4)} ${level === 6 ? "CET" : "MTZ"}`;
  };

  const getImageBasedOnLevel = (level: number) => {
    switch (level) {
      case 1:
        return main_lv1;
      case 2:
        return main_lv2;
      case 3:
        return main_lv3;
      case 4:
        return main_lv4;
      case 5:
        return main_lv5;
      case 6:
        return main_lv6;
      default:
        return main_lv1;
    }
  };

  if (!isVisible) return null;

  return (
    <div
      className="fixed inset-0 z-50 bg-black bg-opacity-50 transition-opacity duration-300"
      style={{ opacity: isShowPopup ? 1 : 0 }}
    >
      <div
        className="fixed left-0 right-0 bottom-0 bg-white rounded-t-2xl p-4 transition-transform duration-300 ease-in-out transform"
        style={{
          transform: isShowPopup ? "translateY(0)" : "translateY(100%)",
        }}
      >
        <div className="text-3xl font-bold leading-6 text-center text-zinc-800 mb-6">
          CAT
        </div>
        <div className="text-base leading-6 text-center text-zinc-800 font-normal mb-6">
          {isMaxLevel
            ? "Maximum Level Reached"
            : "Boost Yield To Earn More MTZ"}
        </div>
        <div className="flex gap-4 justify-between mb-6">
          <div className="flex justify-center items-center h-20 w-20 border-2 border-[#3F2800] rounded-xl overflow-hidden">
            <img
              src={getImageBasedOnLevel(currentRewardLevel)}
              className="w-full h-full object-cover"
              alt="CAT Level"
            />
          </div>
          <div className="flex flex-col flex-1 justify-center">
            <div className="flex justify-between mb-2">
              <div>Level {currentRewardLevel}</div>
              <div>
                {formatRewardAmount(currentRewardAmount, currentRewardLevel)} /
                2h
              </div>
            </div>
            <div className="h-4 bg-zinc-300 rounded-full relative mb-2">
              <div
                className="h-full bg-[#FEBB56] rounded-full absolute left-0 top-0"
                style={{ width: `${progressPercentage}%` }}
              />
            </div>
            {!isMaxLevel && (
              <div className="flex justify-between">
                <div>Next Level</div>
                <div>
                  {formatRewardAmount(nextRewardAmount, currentRewardLevel + 1)}{" "}
                  / 2h
                </div>
              </div>
            )}
          </div>
        </div>
        {currentRewardLevel === 5 && (
          <div className="mb-6">
            <div className="text-base leading-6 text-center text-zinc-800 mb-2">
              CET Claimers: {cetClaimers}/{MAX_CET_CLAIMERS}
            </div>
            <div className="h-4 bg-zinc-300 rounded-full relative">
              <div
                className="h-full bg-[#FEBB56] rounded-full absolute left-0 top-0"
                style={{ width: `${cetClaimerProgressPercentage}%` }}
              />
            </div>
          </div>
        )}
        {!isMaxLevel && (
          <div className="text-base leading-6 text-center text-zinc-800 mb-6">
            Upgrade Cost: {formattedUpgradeCost} MTZ
          </div>
        )}
        <div className="flex gap-4">
          <button
            onClick={onClose}
            className="flex-1 py-4 border border-solid border-zinc-800 rounded-full text-zinc-800"
          >
            {isMaxLevel ? "Close" : "Cancel"}
          </button>
          {!isMaxLevel && (currentRewardLevel < 5 || canUpgradeToLevel5) && (
            <button
              onClick={onUpgrade}
              className="flex-1 flex items-center justify-center py-4 text-[#252525] border border-solid shadow-2xl border-zinc-800 bg-[#FEBB56] rounded-full"
            >
              <img
                src={icon_METAZ_black}
                className="w-6 h-6 mr-2"
                alt="METAZ Icon"
              />
              <span>{formattedUpgradeCost}</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PopupUpgradeCAT;
