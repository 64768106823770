import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ITemMission from "./ITemMission";
import { RootState } from "../redux/store";
import { checkInApi, taskApi } from "../auth/telegramAuth";
import { triggerHapticFeedback } from "../utils/utils";
import { CheckCircle, XCircle } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "../components/ui/alert";
import { useToast } from "./hooks/use-toast";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import { Button } from "../components/ui/button";

interface Task {
  isStarted: boolean;
  _id: string;
  title: string;
  description: string;
  reward: number;
  groupId: string;
  groupLink: string;
  isActive: boolean;
  type: string;
  isPending: boolean;
}

function GameMissionAvailable() {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [streak, setStreak] = useState(0);
  const [checkedDays, setCheckedDays] = useState(Array(10).fill(false));
  const [hasCheckedInToday, setHasCheckedInToday] = useState(false);
  const { toast } = useToast();

  const jwt = useSelector((state: RootState) => state.jwt.token);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!jwt) {
          throw new Error("No token available");
        }
        const [tasksResponse, checkInResponse] = await Promise.all([
          taskApi.getTasks(jwt),
          checkInApi.getCheckInStatus(jwt),
        ]);
        setHasCheckedInToday(!checkInResponse.canCheckIn);
        setTasks(tasksResponse.tasks);
        setStreak(checkInResponse.consecutiveDays);
        setCheckedDays(
          Array(10)
            .fill(false)
            .map((_, index) => index < checkInResponse.consecutiveDays)
        );
        setError("");
      } catch (err) {
        console.error(err);
        setError("Failed to fetch data. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [jwt]);

  const onStartTask = async (taskId: string) => {
    triggerHapticFeedback("medium");
    try {
      if (!jwt) throw new Error("No token available");
      await taskApi.startTask(jwt, taskId);
    } catch (error: any) {
      handleTaskError(error, "start");
    }
  };

  const onCheckTask = async (taskId: string, email?: string, _id?: string) => {
    triggerHapticFeedback("medium");
    try {
      if (!jwt) throw new Error("No token available");
      if (email && _id) {
        await taskApi.registerCoinExTask(jwt, email, _id);
        return true;
      } else {
        const response = await taskApi.checkTask(jwt, taskId);
        if (response.isPending) {
          toast({
            description:
              "Task is pending approval, the process could take up to 1 hour.",
            title: "Task submitted",
            duration: 5000,
          });
        }
        return response;
      }
    } catch (error: any) {
      handleTaskError(error, "check");
      throw error;
    }
  };

  const onClaimTask = async (taskId: string) => {
    triggerHapticFeedback("medium");
    try {
      if (!jwt) throw new Error("No token available");
      await taskApi.claimTask(jwt, taskId);
    } catch (error: any) {
      handleTaskError(error, "claim");
      throw error;
    }
  };

  const handleCheckIn = async () => {
    triggerHapticFeedback("medium");
    try {
      if (!jwt) throw new Error("No token available");
      if (hasCheckedInToday) {
        setError("You've already checked in for today!");
        return;
      }
      await checkInApi.dailyCheckIn(jwt);
      const response = await checkInApi.getCheckInStatus(jwt);
      setStreak(response.consecutiveDays);
      setCheckedDays(
        Array(10)
          .fill(false)
          .map((_, index) => index < response.consecutiveDays)
      );
      setHasCheckedInToday(true);
      setError("");
    } catch (error) {
      console.error("Failed to check in:", error);
      setError("Failed to check in. Please try again later.");
    }
  };

  const handleTaskError = (error: any, action: string) => {
    if (error.status === 400) {
      toast({
        description: `Failed to ${action} task. ${error.response.data.message}`,
        variant: "destructive",
        title: "Error",
        duration: 5000,
      });
    } else {
      toast({
        description: `Failed to ${action} task. Please try again later.`,
        variant: "destructive",
        title: "Error",
        duration: 5000,
      });
    }
    console.error(`Failed to ${action} task:`, error);
  };

  const getReward = () => (streak === 10 ? "2 MTZ" : "0.05 MTZ");

  return (
    <div className="space-y-4">
      <p className="text-base text-center">
        Earn more MTZ by completing missions, all missions are available for you
      </p>

      <Card>
        <CardHeader>
          <CardTitle>Daily Check-in</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-5 gap-2 mb-4">
            {checkedDays.map((checked, index) => (
              <div
                key={index}
                className={`flex items-center justify-center p-2 rounded-full ${
                  checked ? "bg-green-500" : "bg-gray-300"
                }`}
              >
                {checked ? (
                  <CheckCircle className="text-white" size={24} />
                ) : (
                  <XCircle className="text-gray-500" size={24} />
                )}
              </div>
            ))}
          </div>
          <Alert>
            <AlertTitle>
              Current Streak: {streak} day{streak !== 1 ? "s" : ""}
            </AlertTitle>
            <AlertDescription>
              {streak === 10
                ? "Congratulations! You've reached 10 consecutive days. You'll receive 2 MTZ and your streak will reset."
                : `Keep it up! You'll receive ${getReward()} for today's check-in. Reach 10 consecutive days for a 2 MTZ bonus!`}
            </AlertDescription>
          </Alert>
          {error && <p className="mt-2 text-red-500">{error}</p>}
          {hasCheckedInToday ? (
            <p className="mt-4 p-2 bg-green-100 text-green-800 rounded">
              You've already checked in for today. Great job!
            </p>
          ) : (
            <Button onClick={handleCheckIn} className="mt-4">
              Check In
            </Button>
          )}
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Special Missions</CardTitle>
        </CardHeader>
        <CardContent>
          {isLoading ? (
            <p className="text-center">Loading tasks...</p>
          ) : error ? (
            <p className="text-center text-red-500">{error}</p>
          ) : tasks.length > 0 ? (
            tasks.map((task, index) => (
              <ITemMission
                key={task.title + index}
                data={{
                  _id: task._id,
                  title: task.title,
                  description: task.description,
                  reward: task.reward,
                  groupLink: task.groupLink,
                  isStarted: task.isStarted,
                  type: task.type,
                }}
                onStart={async () => await onStartTask(task._id)}
                onCheck={async (email, _id) =>
                  await onCheckTask(task._id, email, _id)
                }
                onClaim={async () => await onClaimTask(task._id)}
                isStarted={task.isStarted}
                isPending={task.isPending}
              />
            ))
          ) : (
            <p className="text-center">No tasks available at the moment.</p>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

export default GameMissionAvailable;
