import { Outlet } from "react-router-dom";
import ConfirmPopup from "../components/ConfirmPopup";
import Notification from "../components/notification/Notification";

export default function Root() {
  return (
    <>
      <div
        id="container"
        className="w-full max-w-[480px] mx-auto min-h-screen flex flex-col justify-start bg-white"
      >
        <ConfirmPopup />
        <Notification />
        <div className="relative flex-1 flex">
          <Outlet />
        </div>
      </div>
      {/* <NavBar /> */}
    </>
  );
}
