import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataContext from "../context/DataContext";
import { useSelector } from "react-redux";
import { getUserData } from "../redux/slices/userSlices";
import GameMissionAvailable from "../components/GameMissionAvailable";
import GameMissionCompleted from "../components/GameMissionCompleted";
import { triggerHapticFeedback } from "../utils/utils";

function GameMission() {
  const navigate = useNavigate();
  const dataUser = useSelector(getUserData);
  const [tab, setTab] = useState<string>("mission");
  const { userTele, wallet, setWallet, setButtonBackTele } =
    useContext(DataContext)!;

  useEffect(() => {
    if (!dataUser) {
      navigate("/");
    }
    setButtonBackTele(true, onCallBackButtonBack);
  }, [dataUser]);

  const onCallBackButtonBack = () => {
    navigate("/game/play");
  };

  const onChangeTab = (str: string) => {
    triggerHapticFeedback("medium");
    setTab(str);
  };

  return (
    <>
      <div className="flex flex-col pb-8 mx-auto w-full text-sm font-semibold leading-5 text-zinc-800">
        <div className="flex flex-col px-3 py-4 w-full">
          <div className="flex gap-2 justify-center text-base leading-6 text-center  border border-solid border-[#333333] rounded-[40px]">
            {tab === "mission" ? (
              <>
                <div className="flex-1 justify-center py-4 text-[#333333] shadow-2xl shadow-[0px_8px_34px_#87a2ff33] border border-solid border-[#333333] bg-[#fdb357] rounded-[40px]">
                  Mission
                </div>
                <div
                  onClick={() => {
                    onChangeTab("completed");
                  }}
                  className="flex-1 justify-center py-4 text-zinc-800 text-opacity-50"
                >
                  Completed
                </div>
              </>
            ) : (
              <>
                <div
                  onClick={() => {
                    onChangeTab("mission");
                  }}
                  className="flex-1 justify-center py-4 text-zinc-800 text-opacity-50"
                >
                  Mission
                </div>
                <div className="flex-1 justify-center py-4 text-[#333333] shadow-2xl shadow-[0px_8px_34px_#87a2ff33] border border-solid border-[#333333] bg-[#fdb357] rounded-[40px]">
                  Completed
                </div>
              </>
            )}
          </div>
        </div>
        <div className="flex flex-col px-3 py-4 w-full">
          {tab === "mission" ? (
            <GameMissionAvailable />
          ) : (
            <GameMissionCompleted />
          )}
        </div>
      </div>
    </>
  );
}

export default GameMission;
