import React, { useState, useEffect } from "react";
import {
  MessageCircle,
  Twitter,
  Youtube,
  Repeat,
  XCircle,
  XIcon,
  DonutIcon,
  ExternalLink,
} from "lucide-react";
import icon_done from "../assets/icon-done.svg";
import { TwitterLogoIcon } from "@radix-ui/react-icons";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "../components/ui/dialog";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { Card, CardContent } from "../components/ui/card";

import telegramLogo from "../assets/telegram_logo.svg";
import twitterLogo from "../assets/x_logo.svg";
import coinexLogo from "../assets/coinex_logo.webp";

interface ITemMissionData {
  _id: string;
  title: string;
  description: string;
  reward: number;
  groupLink: string;
  isStarted: boolean;
  type: string;
}

type Props = {
  data: ITemMissionData;
  onStart?: () => Promise<void>;
  onCheck?: (email?: string, _id?: string) => Promise<any>;
  onClaim?: () => Promise<void>;
  isCompleted?: boolean;
  isStarted?: boolean;
  isPending?: boolean;
  initialStatus?: "notStarted" | "started" | "checked" | "claimed";
};

const ITemMission: React.FC<Props> = ({
  data,
  onStart,
  onCheck,
  onClaim,
  isCompleted = false,
  isStarted = false,
  isPending = false,
  initialStatus = "notStarted",
}) => {
  const [status, setStatus] = useState<
    "notStarted" | "started" | "checked" | "claimed" | "pending"
  >(isCompleted ? "claimed" : isPending ? "pending" : initialStatus);
  const [isLoading, setIsLoading] = useState(false);
  const [showEmailDialog, setShowEmailDialog] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (isCompleted) {
      setStatus("claimed");
    } else if (isPending) {
      setStatus("pending");
    } else if (isStarted) {
      setStatus("started");
    }
  }, [isCompleted, isStarted, isPending]);

  const handleStart = async () => {
    setIsLoading(true);
    try {
      if (data.type === "coinex") {
        window.open(data.groupLink, "_blank");
        setStatus("started");
      } else {
        if (onStart) {
          await onStart();
        }
        setStatus("started");
        if (data.type === "telegram" || data.type === "telegrambot") {
          window.Telegram.WebApp.openTelegramLink(data.groupLink);
        } else {
          window.open(data.groupLink, "_blank");
        }
      }
    } catch (error) {
      console.error("Failed to start mission:", error);
    }
    setIsLoading(false);
  };

  const handleCheck = async () => {
    if (!onCheck) return;
    setIsLoading(true);
    try {
      if (data.type === "coinex") {
        setShowEmailDialog(true);
      } else {
        const result = await onCheck();
        if (result) {
          if (result.isPending) {
            setStatus("pending");
          } else {
            setStatus("checked");
          }
        } else {
          alert("Check failed. Please make sure you have completed the task.");
        }
      }
    } catch (error) {
      console.error("Failed to check mission:", error);
    }
    setIsLoading(false);
  };

  const handleEmailSubmit = async () => {
    if (!onCheck) return;
    setIsLoading(true);
    try {
      const result = await onCheck(email, data._id);
      if (result) {
        setStatus("pending");
      } else {
        alert(
          "Check failed. Please make sure you have completed the task and entered the correct email."
        );
      }
    } catch (error) {
      console.error("Failed to check mission:", error);
    }
    setIsLoading(false);
    setShowEmailDialog(false);
  };

  const handleClaim = async () => {
    if (!onClaim) return;
    setIsLoading(true);
    try {
      await onClaim();
      setStatus("claimed");
    } catch (error) {
      console.error("Failed to claim reward:", error);
    }
    setIsLoading(false);
  };

  const openLink = () => {
    if (data.type === "telegram") {
      window.Telegram.WebApp.openTelegramLink(data.groupLink);
    } else {
      window.open(data.groupLink, "_blank");
    }
  };

  const getIcon = () => {
    const iconClass = "w-12 h-12";
    switch (data.type) {
      case "telegram":
      case "telegrambot":
        return <img src={telegramLogo} alt="Telegram" className={iconClass} />;
      case "twitter":
        return <img src={twitterLogo} alt="Twitter" className={iconClass} />;
      case "youtube":
        return <Youtube className={`${iconClass} text-red-500`} />;
      case "daily":
        return <Repeat className={`${iconClass} text-green-500`} />;
      case "x":
        return <img src={twitterLogo} alt="X" className={iconClass} />;
      case "coinex":
        return <img src={coinexLogo} alt="CoinEx" className={iconClass} />;
      default:
        return null;
    }
  };

  return (
    <Card
      className={`w-full mb-4 ${data.type === "coinex" ? "bg-yellow-50" : ""}`}
    >
      <CardContent className="p-4">
        <div className="flex items-start space-x-4">
          <div className="flex-shrink-0">{getIcon()}</div>
          <div className="flex-grow">
            <div className="flex justify-between items-start">
              <div>
                <h3 className="font-semibold text-lg">{data.title}</h3>
                <p className="text-sm text-gray-600">{data.description}</p>
              </div>
              {status === "claimed" && (
                <img
                  loading="lazy"
                  src={icon_done}
                  alt="Done"
                  className="w-6 h-6"
                />
              )}
            </div>
            <div className="flex justify-between items-center mt-4">
              <span className="text-sm font-medium text-green-600">
                Reward: {data.reward} MTZ
              </span>
              <div className="flex space-x-2">
                {renderActionButton()}
                {(status === "started" || status === "checked") && (
                  <Button variant="outline" size="sm" onClick={openLink}>
                    <ExternalLink size={16} />
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </CardContent>

      <Dialog open={showEmailDialog} onOpenChange={setShowEmailDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Enter Registered Email</DialogTitle>
            <DialogDescription>
              Please enter the email you used to register for the CoinEx task.
            </DialogDescription>
          </DialogHeader>
          <Input
            type="email"
            placeholder="your@email.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <DialogFooter>
            <Button onClick={handleEmailSubmit} disabled={!email}>
              Submit
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </Card>
  );

  function renderActionButton() {
    if (status === "notStarted" && onStart) {
      return (
        <Button
          variant="default"
          size="sm"
          onClick={handleStart}
          disabled={isLoading}
        >
          {isLoading ? "Starting..." : "Start Mission"}
        </Button>
      );
    } else if (status === "started" && onCheck) {
      return (
        <Button
          variant="default"
          size="sm"
          onClick={handleCheck}
          disabled={isLoading}
          className="bg-yellow-500 hover:bg-yellow-600"
        >
          {isLoading ? "Checking..." : "Check Mission"}
        </Button>
      );
    } else if (status === "checked" && onClaim) {
      return (
        <Button
          variant="default"
          size="sm"
          onClick={handleClaim}
          disabled={isLoading}
          className="bg-green-500 hover:bg-green-600"
        >
          {isLoading ? "Claiming..." : "Claim Reward"}
        </Button>
      );
    } else if (status === "pending") {
      return (
        <span className="text-sm font-medium text-yellow-600">
          Pending Approval
        </span>
      );
    } else if (status === "claimed") {
      return (
        <span className="text-sm font-medium text-green-600">Completed</span>
      );
    }
    return null;
  }
};

export default ITemMission;
