import { useContext, useEffect, useState } from "react";
import DataContext from "../context/DataContext";
import {
  getUserData,
  updateData,
  updatePrivateKey,
  updateWallet,
} from "../redux/slices/userSlices";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../components/Button";
import ToastProvider from "../hooks/useToastProvider";
import {
  decryptWallet,
  encryptData,
  encryptWallet,
  newWallet,
  triggerHapticFeedback,
} from "../utils/utils";
import logo from "../assets/logo.png";
import CustomTextarea from "../components/Textarea";
import BoxInputKey from "../components/BoxInputKey";
import { Card, CardContent } from "../components/ui/card";
import { useToast } from "../components/hooks/use-toast";
import { TELEGRAM_BOT_URL } from "../components/constants/constant";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../components/ui/alert-dialog";
import { Input } from "../components/ui/input";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const dataUser = useSelector(getUserData);
  const [page, setPage] = useState("");
  const { userTele, wallet, setWallet, setButtonBackTele } =
    useContext(DataContext)!;
  const [txtPrivateKey, setTxtPrivateKey] = useState("");
  const { toast } = useToast();

  const [isBackupDialogOpen, setIsBackupDialogOpen] = useState(false);
  const [backupPassword, setBackupPassword] = useState("");
  const [backupLink, setBackupLink] = useState("");

  const [createdData, setCreatedData] = useState<any>(null);

  const initData = window.Telegram?.WebApp.initDataUnsafe;

  useEffect(() => {
    if (dataUser) {
      navigate("/");
    }
    // Check for start_param
    if (
      initData.start_param &&
      initData.start_param.startsWith("private_key=")
    ) {
      const encryptedData = initData.start_param.split("private_key=")[1];
      navigate(`/secret-key/${encryptedData}`, { replace: true });
    }
  }, [dataUser]);

  useEffect(() => {
    if (page == "") {
      setButtonBackTele(false, onCallBackButtonBack);
    } else {
      setButtonBackTele(true, onCallBackButtonBack);
    }
  }, [page]);

  const onLogin = () => {
    triggerHapticFeedback("medium");
    setPage("login");
  };

  const resetWallet = () => {
    setWallet(null);
    setTxtPrivateKey("");
  };

  const onCallBackButtonBack = () => {
    setPage("");
    resetWallet();
  };

  const onLoginWallet = async () => {
    triggerHapticFeedback("medium");
    if (txtPrivateKey !== "") {
      const w = await newWallet(txtPrivateKey.trim());
      if (w != null) {
        setWallet(w);
        dispatch(updatePrivateKey(w.privateKey));
      } else {
        toast({
          variant: "destructive",
          description: "Invalid private key or seed phrase",
          duration: 3000,
        });
      }
    } else {
      toast({
        variant: "destructive",
        description: "Please enter the private key or seed phrase",
        duration: 3000,
      });
    }
  };

  const onRegister = async () => {
    triggerHapticFeedback("medium");
    setPage("register");

    const w = await newWallet();
    if (w != null) {
      setWallet(w);
      dispatch(updatePrivateKey(w.privateKey));
    }
  };

  const onCreate = () => {
    triggerHapticFeedback("medium");
    if (backupLink) {
      dispatch(updateData(createdData as any));
    } else {
      if (wallet) {
        const dt = encryptWallet(wallet, userTele.user.id.toString());
        const w = decryptWallet(dt, userTele.user.id.toString());

        const data = { wallet: dt, telegram: userTele.user };
        // dispatch(updateData(data as any));
        setCreatedData(data);
        setWallet(w);

        if (page === "register") {
          setIsBackupDialogOpen(true); // Open backup reminder dialog
        } else {
          toast({
            description: "Login Wallet success",
            duration: 3000,
          });
          dispatch(updateData(data as any));
        }
      } else {
        toast({
          variant: "destructive",
          description: "Please create or login wallet",
          duration: 3000,
        });
      }
    }
  };

  const skipGenerateBackupLink = () => {
    setIsBackupDialogOpen(false);
    toast({
      description: "Backup link skipped",
      duration: 3000,
    });
    dispatch(updateData(createdData as any));
  };

  const generateBackupLink = () => {
    if (wallet && backupPassword) {
      try {
        const privateKeyData = {
          privateKey: wallet.phrase,
          timestamp: Date.now(),
          version: "1.0",
        };

        const encryptedData = encryptData(
          JSON.stringify(privateKeyData),
          backupPassword
        );
        const encodedData = encodeURIComponent(encryptedData);
        const link = `${TELEGRAM_BOT_URL()}private_key=${encodedData}`;

        setBackupLink(link);
        toast({
          description: "Backup link generated successfully",
          duration: 3000,
        });
      } catch (error) {
        console.error("Encryption failed:", error);
        toast({
          variant: "destructive",
          description: "Failed to generate backup link",
          duration: 3000,
        });
      }
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center p-4">
      {page === "" ? (
        <WelcomePage
          onRegister={onRegister}
          onLogin={onLogin}
          isLoading={isLoading}
        />
      ) : (
        <Card className="w-full max-w-md">
          <CardContent className="p-6">
            {page === "register" ? (
              <RegisterPage
                wallet={wallet}
                onCreate={onCreate}
                isLoading={isLoading}
                backupLink={backupLink}
              />
            ) : page === "login" ? (
              wallet == null ? (
                <LoginInputPage
                  txtPrivateKey={txtPrivateKey}
                  setTxtPrivateKey={setTxtPrivateKey}
                  onLoginWallet={onLoginWallet}
                  isLoading={isLoading}
                />
              ) : (
                <LoginConfirmPage
                  wallet={wallet}
                  onCreate={onCreate}
                  isLoading={isLoading}
                />
              )
            ) : null}
          </CardContent>
        </Card>
      )}
      <AlertDialog
        open={isBackupDialogOpen}
        onOpenChange={setIsBackupDialogOpen}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Backup Your Private Key</AlertDialogTitle>
            <AlertDialogDescription>
              It's crucial to backup your private key. Would you like to
              generate a secure backup link now?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <Input
            type="password"
            placeholder="Enter a secure password"
            value={backupPassword}
            onChange={(e) => setBackupPassword(e.target.value)}
          />
          <AlertDialogFooter>
            <AlertDialogCancel onClick={skipGenerateBackupLink}>
              Skip
            </AlertDialogCancel>
            <AlertDialogAction onClick={generateBackupLink}>
              Generate Backup Link
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}

const WelcomePage = ({
  onRegister,
  onLogin,
  isLoading,
}: {
  onRegister: any;
  onLogin: any;
  isLoading: any;
}) => (
  <div className="flex flex-col items-center w-full max-w-md text-center">
    <img src={logo} alt="Logo" className="w-full max-w-sm aspect-square mb-8" />
    <Card className="w-full">
      <CardContent className="p-6">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">
          Welcome to MetaCat Wallet
        </h2>
        <p className="text-sm text-gray-600 mb-6">
          Next generation Telegram wallet. SECURE, FAST over the CoinEx Smart
          Chain
        </p>
        <Button
          isLoading={isLoading}
          className="w-full mb-4"
          label="Create new account"
          handleClick={onRegister}
        />
        <p className="text-sm">
          <span className="text-gray-600">I already have a wallet. </span>
          <button
            onClick={onLogin}
            className="font-medium text-[#FEBB56] hover:text-[#FFA726] focus:outline-none focus:underline transition ease-in-out duration-150"
          >
            Login
          </button>
        </p>
      </CardContent>
    </Card>
  </div>
);

const RegisterPage = ({
  wallet,
  onCreate,
  isLoading,
  backupLink,
}: {
  wallet: any;
  onCreate: any;
  isLoading: any;
  backupLink?: string;
}) => (
  <>
    <h2 className="text-3xl font-extrabold text-gray-900 mb-6">
      Create account
    </h2>
    <BoxInputKey
      title="Address"
      description="We have created a unique CoinEx address for you"
      content={wallet?.address}
      isShowDefault={true}
    />
    <BoxInputKey
      title="Private key"
      description="Copy your private key right now to avoid losing your account!"
      content={wallet?.privateKey}
      isShowDefault={false}
    />
    <BoxInputKey
      title="Seed phrase"
      description="Copy your seed phrase right now to avoid losing your account!"
      content={wallet?.phrase}
      isShowDefault={false}
    />
    {backupLink && (
      <BoxInputKey
        title="Backup Link"
        description="Copy your seed backup link to avoid losing access to your account!"
        content={backupLink}
        isShowDefault={true}
      />
    )}

    <Button
      isLoading={isLoading}
      className="w-full mt-6"
      label={backupLink ? "Done" : "Create"}
      handleClick={onCreate}
    />
  </>
);

const LoginInputPage = ({
  txtPrivateKey,
  setTxtPrivateKey,
  onLoginWallet,
  isLoading,
}: {
  txtPrivateKey: any;
  setTxtPrivateKey: any;
  onLoginWallet: any;
  isLoading: any;
}) => (
  <>
    <h2 className="text-3xl font-extrabold text-gray-900 mb-6">
      Recover using seed phrase
    </h2>
    <p className="mt-2 text-sm text-gray-600">
      Enter the backup passphrase associated with the account.
    </p>
    <CustomTextarea
      className="mt-4 rounded-md border border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 h-32"
      type="text"
      placeholder="Private key or Seedphrase"
      value={txtPrivateKey}
      onChange={(e) => setTxtPrivateKey(e.target.value)}
    />
    <Button
      isLoading={isLoading}
      className="w-full mt-6"
      label="Continue"
      handleClick={onLoginWallet}
    />
  </>
);

const LoginConfirmPage = ({
  wallet,
  onCreate,
  isLoading,
}: {
  wallet: any;
  onCreate: any;
  isLoading: any;
}) => (
  <>
    <h2 className="text-3xl font-extrabold text-gray-900 mb-6">
      Login account
    </h2>
    <BoxInputKey
      title="Address"
      description="We have created a unique CoinEx address for you"
      content={wallet?.address}
      isShowDefault={true}
    />
    <BoxInputKey
      title="Private key"
      description="Copy your private key right now to avoid losing your account!"
      content={wallet?.privateKey}
      isShowDefault={false}
    />
    {wallet?.phrase && (
      <BoxInputKey
        title="Seed phrase"
        description="Copy your seed phrase right now to avoid losing your account!"
        content={wallet?.phrase}
        isShowDefault={false}
      />
    )}
    <Button
      isLoading={isLoading}
      className="w-full mt-6"
      label="Done"
      handleClick={onCreate}
    />
  </>
);

export default Login;
