import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../redux/store";
import { getUserData } from "../redux/slices/userSlices";
import {
  checkMTZWithdraw,
  fetchMTZLockedBalance,
  fetchUser,
  withdrawCET,
  withdrawMTZ,
} from "../redux/slices/metazGameSlice";
import DataContext from "../context/DataContext";
import { useToast } from "../components/hooks/use-toast";
import BlockchainInteraction from "../components/LoadingScreen";
import ITemToken from "../components/ITemToken";
import {
  formatBalance,
  formatDecimalString,
  formatShortAddress,
  triggerHapticFeedback,
} from "../utils/utils";
import {
  CLAIM_INTERVALS,
  REWARD_AMOUNTS,
  BASE_CLAIM_INTERVAL,
} from "../components/constants/constant";
import {
  icon_transfer,
  icon_swap,
  icon_menu,
  icon_invite,
  icon_copy,
  icon_cet,
  img_claim,
  icon_METAZ,
  icon_next,
} from "../assets/icons";
import { claimMTZPendingRewards } from "../auth/telegramAuth";

function Home() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { toast } = useToast();
  const dataUser = useSelector(getUserData);
  const { user, loading, error } = useSelector(
    (state: RootState) => state.metaZGame
  );
  const { userTele, wallet, setWallet, balanceToken, setButtonBackTele } =
    useContext(DataContext)!;

  const [pendingReward, setPendingReward] = useState("Loading...");
  const [interactionState, setInteractionState] = useState<
    "idle" | "loading" | "success" | "failure"
  >("idle");
  const [interactionProgress, setInteractionProgress] = useState(0);
  const [errorMess, setErrorMess] = useState("");

  const jwt = useSelector((state: RootState) => state.jwt.token);

  useEffect(() => {
    if (!dataUser) {
      navigate("/");
    }
    setButtonBackTele(false, onCallBackButtonBack);
    dispatch(fetchUser());
    dispatch(fetchMTZLockedBalance());
  }, [dataUser, dispatch, navigate, setButtonBackTele]);

  useEffect(() => {
    const interval = setInterval(calculatePendingReward, 1000);
    return () => clearInterval(interval);
  }, [user]);

  const onCallBackButtonBack = () => {};

  const navigateWithHapticFeedback = (path: string) => {
    triggerHapticFeedback("medium");
    navigate(path);
  };

  const calculatePendingReward = () => {
    if (!user || user.lastHarvestTime === "0") {
      setPendingReward("0.01");
      return;
    }

    const now = Date.now() / 1000;
    const claimLevel = Number(user.claimLevel);
    const rewardLevel = Number(user.rewardLevel);
    const claimInterval = CLAIM_INTERVALS[claimLevel];
    const timeSinceLastHarvest = now - Number(user.lastHarvestTime);

    let reward;
    if (timeSinceLastHarvest >= claimInterval) {
      reward =
        REWARD_AMOUNTS[rewardLevel] * (claimInterval / BASE_CLAIM_INTERVAL);
    } else {
      reward =
        (REWARD_AMOUNTS[rewardLevel] *
          timeSinceLastHarvest *
          (claimInterval / BASE_CLAIM_INTERVAL)) /
        claimInterval;
    }

    setPendingReward((reward / 1e18).toFixed(5));
  };

  const handleClaim = async () => {
    triggerHapticFeedback("medium");
    setInteractionState("loading");
    setInteractionProgress(0);

    try {
      // Simulate claim process
      for (let i = 0; i <= 50; i += 10) {
        setInteractionProgress(i);
        await new Promise((resolve) => setTimeout(resolve, 200));
      }

      if (!user) {
        throw new Error("User data not found");
      }

      await dispatch(withdrawCET({ amount: user.cetBalance })).unwrap();

      // Continue progress simulation after harvest
      for (let i = 60; i <= 100; i += 10) {
        setInteractionProgress(i);
        await new Promise((resolve) => setTimeout(resolve, 200));
      }

      await dispatch(fetchUser());

      setInteractionState("success");
      toast({
        description: "Claim successful!",
      });
    } catch (error: any) {
      console.error("Error during claim process:", error);
      setErrorMess(error.message);
      setInteractionState("failure");
    }
  };

  const handleClaimMTZ = async () => {
    triggerHapticFeedback("medium");
    setInteractionState("loading");
    setInteractionProgress(0);

    try {
      // Simulate claim process
      for (let i = 0; i <= 50; i += 10) {
        setInteractionProgress(i);
        await new Promise((resolve) => setTimeout(resolve, 200));
      }

      if (!user) {
        throw new Error("User data not found");
      }

      if (!jwt) {
        throw new Error("JWT token not found");
      }

      // Check user balance
      await dispatch(checkMTZWithdraw({ amount: "0" })).unwrap();

      // get claim signature
      const dataSignature = await claimMTZPendingRewards(jwt);

      const amount = dataSignature.amount;
      const signature = dataSignature.signature;
      const nonce = dataSignature.nonce;
      const userAddress = wallet.address;

      await dispatch(
        withdrawMTZ({ amount, signature, user: userAddress, nonce })
      ).unwrap();

      // Continue progress simulation after harvest
      for (let i = 60; i <= 100; i += 10) {
        setInteractionProgress(i);
        await new Promise((resolve) => setTimeout(resolve, 200));
      }

      await dispatch(fetchMTZLockedBalance());

      setInteractionState("success");
      toast({
        description: "Claim successful!",
      });
    } catch (error: any) {
      console.error("Error during claim process:", error);
      await dispatch(fetchMTZLockedBalance());
      setErrorMess(error.message);
      setInteractionState("failure");
    }
  };

  const copyToClipboard = (content: string) => {
    navigator.clipboard.writeText(content);
    toast({
      title: "Copied to clipboard",
      description: "Address has been copied to your clipboard.",
    });
  };

  const closeInteraction = () => {
    triggerHapticFeedback("medium");
    setInteractionState("idle");
    setInteractionProgress(0);
  };

  const nextClaimTime = Number(user?.lastCETClaimTime) + 7 * 24 * 60 * 60; // 7 days in seconds

  return (
    <>
      <BlockchainInteraction
        state={interactionState}
        progress={interactionProgress}
        errorMesssage={errorMess}
        onClose={closeInteraction}
      />
      <div className="flex flex-col p-4 mx-auto w-full text-base max-w-[480px] text-zinc-800 bg-white shadow-lg rounded-lg">
        {/* Header */}
        <header className="flex justify-between py-4 w-full text-base font-bold leading-6 text-center text-zinc-800">
          <div className="flex gap-3 w-full">
            <div
              className="flex flex-col w-[33%]"
              onClick={() => navigateWithHapticFeedback("/game/settings")}
            >
              <img
                src={icon_menu}
                className="shrink-0 w-6 aspect-square cursor-pointer"
                alt="Menu"
              />
            </div>
            <div className="flex flex-col w-[33%]">
              {dataUser.telegram.username}
            </div>
            <div
              className="flex flex-col w-[33%] items-end"
              onClick={() => navigateWithHapticFeedback("/game/invite-friend")}
            >
              <div className="flex flex-1 gap-2.5 whitespace-nowrap cursor-pointer">
                <img
                  src={icon_invite}
                  className="shrink-0 w-6 aspect-square"
                  alt="Invite"
                />
                <div>Invite</div>
              </div>
            </div>
          </div>
        </header>

        {/* Main Content */}
        <main className="flex flex-col px-3 py-4 w-full">
          {/* Wallet Address */}
          <div className="flex gap-2 self-center text-base font-medium whitespace-nowrap text-zinc-800">
            <div>{formatShortAddress(wallet ? wallet.address : "")}</div>
            <img
              src={icon_copy}
              className="shrink-0 w-6 aspect-square cursor-pointer"
              onClick={() => copyToClipboard(wallet.address)}
              alt="Copy address"
            />
          </div>

          {/* Balance */}
          <div className="flex gap-2 self-center mt-5 text-3xl font-bold text-center whitespace-nowrap text-zinc-800">
            <img
              src={icon_cet}
              className="shrink-0 my-auto w-6 aspect-[0.75] fill-zinc-800"
              alt="CET icon"
            />
            <div>
              {balanceToken && balanceToken.length > 0
                ? formatDecimalString(
                    formatBalance(
                      balanceToken[0].balance,
                      balanceToken[0].decimals
                    ),
                    6
                  )
                : 0}
            </div>
          </div>

          {/* Action Buttons */}
          <div className="justify-center px-2 mt-5">
            <div className="flex gap-8 justify-center items-start max-w-[406px]">
              <ActionButton
                icon={icon_transfer}
                text="Transfer"
                onClick={() => navigateWithHapticFeedback("/game/transfer")}
              />
              <ActionButton
                icon={icon_swap}
                text="Swap"
                onClick={() => navigateWithHapticFeedback("/game/swap")}
              />
            </div>
          </div>

          {/* Game Section */}
          <GameSection
            pendingReward={pendingReward}
            onGotoGame={() => navigateWithHapticFeedback("/game/play")}
          />

          {/* Token Section */}
          <div className="mt-5 text-xl font-semibold leading-8 text-zinc-800">
            Token
          </div>

          {balanceToken &&
            balanceToken.length > 0 &&
            balanceToken.map((item: any, index: number) => (
              <ITemToken
                data={item}
                key={index}
                onClaim={handleClaim}
                onClaimMTZ={handleClaimMTZ}
                countdown={nextClaimTime * 1000}
              />
            ))}
        </main>
      </div>
    </>
  );
}

interface ActionButtonProps {
  icon: string;
  text: string;
  onClick: () => void;
}

const ActionButton: React.FC<ActionButtonProps> = ({ icon, text, onClick }) => (
  <div className="flex flex-col items-center">
    <div
      onClick={onClick}
      className="flex gap-2.5 justify-center items-center px-4 w-14 h-14 bg-[#FEBB56] rounded-2xl shadow-md cursor-pointer transition-all hover:shadow-lg"
    >
      <img
        src={icon}
        className="object-contain self-stretch my-auto w-6 aspect-square"
        alt={text}
      />
    </div>
    <div className="mt-2 text-base leading-none text-center text-zinc-800">
      {text}
    </div>
  </div>
);

interface GameSectionProps {
  pendingReward: string;
  onGotoGame: () => void;
}

const GameSection: React.FC<GameSectionProps> = ({
  pendingReward,
  onGotoGame,
}) => (
  <div className="flex gap-4 mt-5 items-end self-stretch p-4 bg-[#FEBB56] rounded-2xl shadow-md">
    <div
      onClick={onGotoGame}
      className="flex flex-col flex-1 shrink w-full basis-0 min-w-[240px] cursor-pointer"
    >
      <div className="flex relative gap-10 items-start w-full text-3xl text-neutral-800">
        <div className="flex z-0 flex-col items-start self-end min-w-[240px]">
          <div className="font-black text-center uppercase">METAZ</div>
          <div className="self-stretch mt-2 text-xs leading-none">
            Earn MTZ & CET by playing game
          </div>
          <div className="flex gap-2 items-center mt-4 font-extrabold text-center whitespace-nowrap">
            <img
              src={icon_METAZ}
              className="object-contain shrink-0 self-stretch my-auto w-8 aspect-square rounded-full shadow-sm"
              alt="METAZ icon"
            />
            <div className="self-stretch my-auto">{pendingReward}</div>
          </div>
        </div>
        <img
          src={img_claim}
          className="object-contain absolute right-0 -bottom-6 z-0 shrink-0 self-start w-20 h-20 aspect-square"
          alt="Claim"
        />
      </div>
      <div className="flex flex-col justify-center mt-4 w-full text-base font-bold leading-none text-center text-amber-500">
        <div className="flex justify-end gap-2.5 items-center px-4 py-3 w-full bg-neutral-800 rounded-[40px] shadow-lg hover:shadow-xl transition-all">
          <div className="self-stretch my-auto">Claim now</div>
          <img
            src={icon_next}
            className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square"
            alt="Next"
          />
        </div>
      </div>
    </div>
  </div>
);

export default Home;
