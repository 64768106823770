import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loginUserByAPI } from "../../components/api/authentication";
import { ValuePropsGetUser, DataUser } from "../../utils/interface.global";
import ToastProvider from "../../hooks/useToastProvider";
import { Wallet } from "ethers";

//"user/login"
export const loginUser = createAsyncThunk(
  "user/login",
  async (
    { privateKey }: ValuePropsGetUser,
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const props = {
        privateKey,
      };
      if (privateKey === "") {
        throw rejectWithValue("Please enter full information");
      }
      const response = await loginUserByAPI(props);
      //console.log("loginUser", response);
      if (response.status) {
        ToastProvider({ type: "success", content: "Login successful" });
        //console.log("fulfillWithValue", response);
        return fulfillWithValue(response.data);
      } else {
        ToastProvider({ type: "error", content: response.message });
        throw rejectWithValue(response.message);
      }
    } catch (error) {
      throw rejectWithValue((error as DOMException).message);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    status: false,
    userData: null,
    privateKey: "",
    error: "",
    wallet: null as Wallet | null,
  },
  reducers: {
    updateData: (state: any, action: any) => {
      const tmp = action.payload;
      if (tmp !== null) {
        state.userData = tmp;
        state.status = true;
        state.privateKey = tmp.privateKey;
      } else {
        state.userData = tmp;
        state.status = true;
      }
    },
    logout: (state: any) => {
      state.userData = null;
      state.status = false;
      state.privateKey = "";
    },
    updatePrivateKey: (state, action: PayloadAction<string>) => {
      state.privateKey = action.payload;
    },
    updateWallet: (state, action: PayloadAction<Wallet>) => {
      state.wallet = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      // register user
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = true;
        state.userData = action.payload;
        state.privateKey = action.payload.privateKey;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = false;
        state.userData = null;
        state.error = action.payload as string;
        state.privateKey = "";
      });
  },
});

//Reducers
export const { logout, updateData, updatePrivateKey, updateWallet } =
  userSlice.actions;

// actions
export const getUserData = (state: { user: { userData: DataUser } }) =>
  state.user?.userData;
export const getUserStatus = (state: { user: { status: boolean } }) =>
  state.user?.status;
export const getUsertErr = (state: { user: { error: string } }) =>
  state.user?.error;

// reducer
export default userSlice.reducer;
