import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUserData } from "../redux/slices/userSlices";
import ITemMission from "./ITemMission";
import { RootState } from "../redux/store";
import { taskApi } from "../auth/telegramAuth";

interface Task {
  _id: string;
  title: string;
  description: string;
  reward: number;
  groupId: string;
  groupLink: string;
  isActive: boolean;
  isStarted: boolean;
  type: string;
}

function GameMissionCompleted() {
  const navigate = useNavigate();
  const dataUser = useSelector(getUserData);
  const [completedTasks, setCompletedTasks] = useState<Task[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  const jwt = useSelector((state: RootState) => state.jwt.token);

  useEffect(() => {
    if (!dataUser) {
      navigate("/");
    }
  }, [dataUser, navigate]);

  useEffect(() => {
    const fetchCompletedTasks = async () => {
      setIsLoading(true);
      try {
        if (!jwt) {
          throw new Error("No token available");
        }
        const response = await taskApi.getCompletedTasks(jwt);
        setError("");
        setCompletedTasks(response.completedTasks);
        setIsLoading(false);
      } catch (err) {
        console.error(err);
        setError("Failed to fetch completed tasks. Please try again later.");
        setIsLoading(false);
      }
    };

    fetchCompletedTasks();
  }, [jwt]);

  return (
    <>
      <div className="mt-4 text-zinc-800">Completed tasks</div>
      <div className="flex flex-col mt-2 text-sm leading-5 rounded-2xl border border-solid border-zinc-800 border-opacity-50 text-zinc-800">
        {isLoading ? (
          <div className="p-4 text-center">Loading completed tasks...</div>
        ) : error ? (
          <div className="p-4 text-center text-red-500">{error}</div>
        ) : completedTasks.length > 0 ? (
          completedTasks.map((task, index) => (
            <ITemMission
              key={task.title + index}
              data={{
                _id: task._id,
                title: task.title,
                description: task.description,
                reward: task.reward,
                groupLink: task.groupLink,
                isStarted: task.isStarted,
                type: task.type,
              }}
              isCompleted={true}
            />
          ))
        ) : (
          <div className="p-4 text-center">No completed tasks yet.</div>
        )}
      </div>
      <div className="mt-4 text-zinc-800">Special</div>
      <div className="flex flex-col mt-2 text-sm leading-5 rounded-2xl border border-solid border-zinc-800 border-opacity-50 text-zinc-800">
        {/* You can add special completed tasks here if needed */}
      </div>
    </>
  );
}

export default GameMissionCompleted;
