import { Hourglass, CheckCircle, XCircle } from "lucide-react";

const BlockchainInteraction = ({
  state,
  progress,
  onClose,
  errorMesssage,
}: {
  state: "idle" | "loading" | "success" | "failure";
  progress: number;
  onClose: () => void;
  errorMesssage?: string;
}) => {
  const renderContent = () => {
    switch (state) {
      case "loading":
        return (
          <>
            <div className="flex items-center justify-between mb-4">
              <span className="text-green-800 font-bold text-lg">
                Connecting to blockchain...
              </span>
              <Hourglass className="text-green-800 animate-spin" size={28} />
            </div>
            <div className="w-full h-4 bg-green-200 rounded-full overflow-hidden">
              <div
                className="h-full bg-green-500 transition-all duration-500 ease-linear rounded-full"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
            <div className="mt-3 text-center text-green-800 font-medium">
              {progress.toFixed(2)}% Complete
            </div>
          </>
        );
      case "success":
        return (
          <div className="text-center">
            <CheckCircle className="mx-auto text-green-500 mb-4" size={48} />
            <h2 className="text-2xl font-bold text-green-800 mb-2">
              Transaction Successful!
            </h2>
            <p className="text-green-700">
              Your blockchain interaction is complete.
            </p>
            <button
              className="mt-4 px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        );
      case "failure":
        return (
          <div className="text-center">
            <XCircle className="mx-auto text-red-500 mb-4" size={48} />
            <h2 className="text-2xl font-bold text-red-800 mb-2">
              Transaction Failed
            </h2>
            <p className="text-red-700">{errorMesssage}</p>
            <button
              className="mt-4 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  if (state === "idle") return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-[100] bg-black bg-opacity-50">
      <div className="bg-amber-100 p-6 rounded-xl shadow-lg max-w-sm w-full">
        {renderContent()}
      </div>
    </div>
  );
};

export default BlockchainInteraction;
