import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import React from "react";

export interface PopUpState {
  isOpen: boolean;
  isOpenInBlankBackground?: boolean;
  content: React.ReactNode | null;
  isHasCloseButton: boolean;
  height: number;
  title: string | React.ReactNode;
  isShowCoin?: boolean;
  tab: {
    tabItems: string[];
    tabsContent: React.ReactNode[];
    activeTab: string;
  };
  buttonConfirm?: {
    text: string;
    onClick: () => void;
  };
  isNoPaddingContent?: boolean;
}

// Define the initial state
const initialState: PopUpState = {
  isOpen: false,
  content: null,
  isHasCloseButton: false,
  height: 60,
  isShowCoin: false,
  title: "",
  tab: {
    tabItems: [],
    tabsContent: [],
    activeTab: "",
  },
  buttonConfirm: {
    text: "",
    onClick: () => {},
  },
  isOpenInBlankBackground: false,
  isNoPaddingContent: false,
};

const popupSlice = createSlice({
  name: "popup",
  initialState,
  reducers: {
    togglePopup: (
      state: any,
      action: PayloadAction<{
        content: React.ReactNode;
        height?: number;
        title?: string | React.ReactNode;
        buttonConfirm?: {
          text: string;
          onClick: () => void;
        };
        isOpenInBlankBackground?: boolean;
        isShowCoin?: boolean;
        isNoPaddingContent?: boolean;
      }>
    ) => {
      state.isOpen = !state.isOpen;
      state.content = action.payload.content;
      state.height = action.payload.height || 60;
      state.title = action.payload.title || "";
      state.isShowCoin = action.payload.isShowCoin || false;
      state.buttonConfirm = action.payload.buttonConfirm || {
        text: "",
        onClick: () => {},
      };
      state.isOpenInBlankBackground =
        action.payload.isOpenInBlankBackground || false;
      state.isNoPaddingContent = action.payload.isNoPaddingContent || false;
    },
    toggleHasClosePopup: (
      state: any,
      action: PayloadAction<{
        content: React.ReactNode;
        height?: number;
        title?: string | React.ReactNode;
        tab?: {
          tabItems: string[];
          tabsContent: React.ReactNode[];
          activeTab: string;
        };
        isShowCoin?: boolean;
        buttonConfirm?: {
          text: string;
          onClick: () => void;
        };
        isOpenInBlankBackground?: boolean;
      }>
    ) => {
      state.isOpen = !state.isOpen;
      state.content = action.payload.content;
      state.title = action.payload.title || "";
      state.height = action.payload.height || 60;
      state.isShowCoin = action.payload.isShowCoin || false;
      state.tab = action.payload.tab || {
        tabItems: [],
        tabsContent: [],
        activeTab: "",
      };
      state.buttonConfirm = action.payload.buttonConfirm || {
        text: "",
        onClick: () => {},
      };
      state.isHasCloseButton = true;
      state.isOpenInBlankBackground =
        action.payload.isOpenInBlankBackground || false;
    },
    closePopup: (state: any) => {
      state.isOpen = false;
      state.content = null;
      state.title = "";
      state.isHasCloseButton = false;
      state.height = 60;
      state.tab = {
        tabItems: [],
        tabsContent: [],
        activeTab: "",
      };
      state.buttonConfirm = {
        text: "",
        onClick: () => {},
      };
      state.isOpenInBlankBackground = false;
      state.isNoPaddingContent = false;
    },
    //change active tab & content
    onClickOtherTab: (state: any, action: PayloadAction<string>) => {
      state.tab.activeTab = action.payload;
    },
  },
});

//Reducers
export const { togglePopup, toggleHasClosePopup, closePopup, onClickOtherTab } =
  popupSlice.actions;
export const getClosePopup = (state: { popup: { isOpen: boolean } }) =>
  state.popup?.isOpen;
// reducer
export default popupSlice.reducer;
