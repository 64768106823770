import React, { useState, useEffect, useCallback } from "react";
import { ethers } from "ethers";
import debounce from "lodash/debounce";
import CustomInput from "./Input";
import { Balance } from "../constants/constants";
import { X } from "lucide-react";
import { Button } from "../components/ui/button";

interface FormData {
  receiver: string;
  token: Balance | null;
  amount: bigint;
}

interface Props {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  tokenSelected: { decimals: bigint } | null;
  onAmountChange: (newAmount: bigint) => void;
}

const AmountInput: React.FC<Props> = ({
  formData,
  setFormData,
  tokenSelected,
  onAmountChange,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    if (!isTyping && formData.amount >= 0) {
      const formatted = formatAmount(
        formData.amount,
        Number(formData.token?.decimals || 18)
      );
      setInputValue(formatted);
    }
  }, [formData.amount, formData.token?.decimals, isTyping]);

  const debouncedAmountChange = useCallback(
    debounce((amount: bigint) => {
      onAmountChange(amount);
    }, 500),
    [onAmountChange]
  );

  const formatAmount = (amount: bigint, decimals: number): string => {
    const formatted = ethers.formatUnits(amount, decimals);
    const [integerPart, fractionalPart] = formatted.split(".");
    if (!fractionalPart || fractionalPart === "0") {
      return integerPart;
    }
    return formatted.replace(/\.?0+$/, "");
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    // Allow empty input or valid number input (including decimal point)
    if (value === "" || /^\d*\.?\d*$/.test(value)) {
      setInputValue(value);
      setIsTyping(true);

      if (value === "") {
        setFormData((prev) => ({ ...prev, amount: BigInt(0) }));
        debouncedAmountChange(BigInt(0));
      } else {
        try {
          const decimals = Number(tokenSelected?.decimals || 18);
          const parsedAmount = ethers.parseUnits(value, decimals);
          const bigIntAmount = BigInt(parsedAmount.toString());

          setFormData((prev) => ({
            ...prev,
            amount: bigIntAmount,
          }));
          debouncedAmountChange(bigIntAmount);
        } catch (error) {
          // Ignore parsing errors during typing
        }
      }
    }
  };

  const handleBlur = () => {
    setIsTyping(false);
    if (formData.token && formData.amount >= 0) {
      const decimals = Number(formData.token.decimals || 18);
      let amount = formData.amount;

      // Check if the amount exceeds the maximum balance
      if (amount > formData.token.balance) {
        amount = formData.token.balance;
        setFormData((prev) => ({ ...prev, amount }));
        onAmountChange(amount);
      }

      const formatted = formatAmount(amount, decimals);
      setInputValue(formatted);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleBlur();
    }
  };

  const handleClear = () => {
    setInputValue("");
    setFormData((prev) => ({ ...prev, amount: BigInt(0) }));
    onAmountChange(BigInt(0));
  };

  return (
    <div className="flex items-center">
      <CustomInput
        className="flex-grow"
        type="text"
        placeholder="Amount"
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
      />
      <Button
        type="button"
        variant="ghost"
        size="icon"
        className="ml-2"
        onClick={handleClear}
      >
        <X className="h-4 w-4" />
        <span className="sr-only">Clear input</span>
      </Button>
    </div>
  );
};

export default AmountInput;
