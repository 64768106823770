import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ethers } from "ethers";
import MetaZGame from "./MetaZGame.json";
import type { RootState } from "../store";
import {
  CONTRACT_ADDRESS,
  METAZ_TOKEN_ADDRESS,
  RPC_URL,
} from "../../components/constants/constant";
import { ERC20_ABI } from "../../constants/constants";
import { fetchMTZPendingRewards } from "../../auth/telegramAuth";

// ABI and contract address would be imported from separate files in a real application
export const CONTRACT_ABI = MetaZGame.abi;
const GAME_CONTRACT_ADDRESS = CONTRACT_ADDRESS();

// Initialize ethers with JSON-RPC provider
const provider = new ethers.JsonRpcProvider(RPC_URL());

// Define the shape of the User object
interface User {
  lastHarvestTime: string;
  rewardLevel: string;
  claimLevel: string;
  referralCount: string;
  unlimitedReferrals: boolean;
  telegramId: string;
  lastCETClaimTime: string;
  cetBalance: string;
  cetClaimers: number;
}

// Define the shape of our state
interface MetaZGameState {
  user: User | null;
  lockedMTZBalance: string;
  loading: boolean;
  error: string | null;
}

const initialState: MetaZGameState = {
  user: null,
  loading: false,
  error: null,
  lockedMTZBalance: "0",
};

// Helper function to get contract instance
const getContract = (signer: ethers.Signer) => {
  return new ethers.Contract(GAME_CONTRACT_ADDRESS, CONTRACT_ABI, signer);
};

// Helper function to wait for transaction
const waitForTransaction = async (tx: ethers.ContractTransactionResponse) => {
  const receipt = await tx.wait();
  return receipt;
};

// Async thunks
export const createUser = createAsyncThunk<
  void,
  { referrer: string; telegramId: string; signature: string },
  { state: RootState }
>(
  "metaZGame/createUser",
  async ({ referrer, telegramId, signature }, { getState, extra }) => {
    const wallet = new ethers.Wallet(getState().user.privateKey, provider);
    const contract = getContract(wallet);
    const tx = await contract.createUser(
      await wallet.getAddress(),
      referrer,
      telegramId,
      signature
    );
    await waitForTransaction(tx);
  }
);

export const harvest = createAsyncThunk<void, void, { state: RootState }>(
  "metaZGame/harvest",
  async (_, { getState }) => {
    const wallet = new ethers.Wallet(getState().user.privateKey, provider);

    // check if user has enough gas to harvest
    const balance = await provider.getBalance(wallet.address);

    const contract = getContract(wallet);
    const gas = await contract.harvest.estimateGas();
    if (balance < ethers.parseUnits("500", "gwei") * gas) {
      throw new Error("Insufficient gas to harvest");
    }
    try {
      const tx = await contract.harvest();
      await waitForTransaction(tx);
    } catch (error) {
      throw new Error("Failed to harvest");
    }
  }
);

export const withdrawCET = createAsyncThunk<
  void,
  {
    amount: string;
  },
  { state: RootState }
>("metaZGame/withdrawCET", async ({ amount }, { getState }) => {
  const wallet = new ethers.Wallet(getState().user.privateKey, provider);

  // check if user has enough gas to harvest
  const balance = await provider.getBalance(wallet.address);

  const contract = getContract(wallet);
  const gas = await contract.withdrawCET.estimateGas(
    ethers.parseUnits(amount, "wei")
  );
  if (balance < ethers.parseUnits("500", "gwei") * gas) {
    throw new Error("Insufficient gas to withdraw CET");
  }
  const tx = await contract.withdrawCET(ethers.parseUnits(amount, "wei"));
  await waitForTransaction(tx);
});

export const checkMTZWithdraw = createAsyncThunk<
  void,
  {
    amount: string;
  },
  { state: RootState }
>("metaZGame/checkMTZWithdraw", async ({ amount }, { getState }) => {
  const wallet = new ethers.Wallet(getState().user.privateKey, provider);

  // check if user has enough gas to harvest
  const balance = await provider.getBalance(wallet.address);

  const contract = getContract(wallet);
  const gas = await contract.withdrawCET.estimateGas(
    ethers.parseUnits(amount, "wei")
  );
  if (balance < ethers.parseUnits("500", "gwei") * gas) {
    throw new Error("Insufficient gas to withdraw CET");
  }
});

export const withdrawMTZ = createAsyncThunk<
  void,
  {
    user: string;
    amount: string;
    signature: string;
    nonce: string;
  },
  { state: RootState }
>(
  "metaZGame/withdrawMTZ",
  async ({ amount, user, signature, nonce }, { getState }) => {
    const wallet = new ethers.Wallet(getState().user.privateKey, provider);

    // check if user has enough gas to harvest
    const balance = await provider.getBalance(wallet.address);

    const contract = getContract(wallet);
    const gas = await contract.missionGift.estimateGas(
      user,
      ethers.parseUnits(amount, "wei"),
      nonce,
      signature
    );
    if (balance < ethers.parseUnits("500", "gwei") * gas) {
      throw new Error("Insufficient gas to withdraw CET");
    }
    const tx = await contract.missionGift(
      user,
      ethers.parseUnits(amount, "wei"),
      nonce,
      signature
    );
    await waitForTransaction(tx);
  }
);

export const upgradeToUnlimitedReferral = createAsyncThunk<
  void,
  void,
  { state: RootState }
>("metaZGame/upgradeReferrals", async (_, { getState }) => {
  const wallet = new ethers.Wallet(getState().user.privateKey, provider);
  // check if user has enough gas to harvest
  const balance = await provider.getBalance(wallet.address);
  const contract = getContract(wallet);
  const erc20Contract = new ethers.Contract(
    METAZ_TOKEN_ADDRESS(),
    ERC20_ABI,
    wallet
  );
  // get current reward level from state
  const user = getState().metaZGame.user;

  // check if user has enough mtz to upgrade
  const mtzBalance = await erc20Contract.balanceOf(wallet.address);

  if (mtzBalance < ethers.parseUnits("20", 18)) {
    throw new Error("Insufficient MTZ to upgrade reward level");
  }

  // check allowance
  const allowance = await erc20Contract.allowance(
    wallet.address,
    GAME_CONTRACT_ADDRESS
  );

  if (allowance < ethers.parseUnits("20", 18)) {
    const estimateGas = await erc20Contract.approve.estimateGas(
      GAME_CONTRACT_ADDRESS,
      ethers.MaxUint256
    );
    if (balance < ethers.parseUnits("500", "gwei") * estimateGas) {
      throw new Error("Insufficient gas to upgrade reward level");
    }

    const tx = await erc20Contract.approve(
      GAME_CONTRACT_ADDRESS,
      ethers.MaxUint256
    );
    await waitForTransaction(tx);
  }
  const gas = await contract.upgradeToUnlimitedReferrals.estimateGas();

  if (balance < ethers.parseUnits("500", "gwei") * gas) {
    throw new Error("Insufficient gas to upgrade referrals to unlimited");
  }

  const tx = await contract.upgradeToUnlimitedReferrals();
  await waitForTransaction(tx);
});

export const upgradeRewardLevel = createAsyncThunk<
  void,
  void,
  { state: RootState }
>("metaZGame/upgradeRewardLevel", async (_, { getState }) => {
  const wallet = new ethers.Wallet(getState().user.privateKey, provider);
  // check if user has enough gas to harvest
  const balance = await provider.getBalance(wallet.address);
  const contract = getContract(wallet);
  const erc20Contract = new ethers.Contract(
    METAZ_TOKEN_ADDRESS(),
    ERC20_ABI,
    wallet
  );
  // get current reward level from state
  const user = getState().metaZGame.user;
  // get next reward level from contract
  const nextRewardLevel = await contract.rewardUpgradeCosts(
    Number(user?.rewardLevel) - 1
  );

  // check if user has enough mtz to upgrade
  const mtzBalance = await erc20Contract.balanceOf(wallet.address);

  if (mtzBalance < nextRewardLevel) {
    throw new Error("Insufficient MTZ to upgrade reward level");
  }

  // check allowance
  const allowance = await erc20Contract.allowance(
    wallet.address,
    GAME_CONTRACT_ADDRESS
  );

  if (allowance < nextRewardLevel) {
    const estimateGas = await erc20Contract.approve.estimateGas(
      GAME_CONTRACT_ADDRESS,
      ethers.MaxUint256
    );
    if (balance < ethers.parseUnits("500", "gwei") * estimateGas) {
      throw new Error("Insufficient gas to upgrade reward level");
    }

    const tx = await erc20Contract.approve(
      GAME_CONTRACT_ADDRESS,
      ethers.MaxUint256
    );
    await waitForTransaction(tx);
  }
  const gas = await contract.upgradeRewardLevel.estimateGas();

  if (balance < ethers.parseUnits("500", "gwei") * gas) {
    throw new Error("Insufficient gas to upgrade reward level");
  }

  const tx = await contract.upgradeRewardLevel();
  await waitForTransaction(tx);
});

export const upgradeClaimLevel = createAsyncThunk<
  void,
  void,
  { state: RootState }
>("metaZGame/upgradeClaimLevel", async (_, { getState }) => {
  const wallet = new ethers.Wallet(getState().user.privateKey, provider);
  // check if user has enough gas to harvest
  const balance = await provider.getBalance(wallet.address);
  const contract = getContract(wallet);
  const erc20Contract = new ethers.Contract(
    METAZ_TOKEN_ADDRESS(),
    ERC20_ABI,
    wallet
  );
  // get current reward level from state
  const user = getState().metaZGame.user;
  // get next reward level from contract
  const nextClaimLevel = await contract.claimUpgradeCosts(
    Number(user?.claimLevel) - 1
  );

  // check if user has enough mtz to upgrade
  const mtzBalance = await erc20Contract.balanceOf(wallet.address);

  if (mtzBalance < nextClaimLevel) {
    throw new Error("Insufficient MTZ to upgrade claim level");
  }
  // check allowance
  const allowance = await erc20Contract.allowance(
    wallet.address,
    GAME_CONTRACT_ADDRESS
  );

  if (allowance < nextClaimLevel) {
    const estimateGas = await erc20Contract.approve.estimateGas(
      GAME_CONTRACT_ADDRESS,
      ethers.MaxUint256
    );
    if (balance < ethers.parseUnits("500", "gwei") * estimateGas) {
      throw new Error("Insufficient gas to upgrade reward level");
    }

    const tx = await erc20Contract.approve(
      GAME_CONTRACT_ADDRESS,
      ethers.MaxUint256
    );
    await waitForTransaction(tx);
  }
  const gas = await contract.upgradeClaimLevel.estimateGas();

  if (balance < ethers.parseUnits("500", "gwei") * gas) {
    throw new Error("Insufficient gas to upgrade claim level");
  }

  const tx = await contract.upgradeClaimLevel();
  await waitForTransaction(tx);
});

export const fetchUser = createAsyncThunk<User, void, { state: RootState }>(
  "metaZGame/fetchUser",
  async (_, { getState }) => {
    const wallet = new ethers.Wallet(getState().user.privateKey, provider);
    const contract = getContract(wallet);
    const address = await wallet.getAddress();
    const user = await contract.users(address);
    const cetClaimers = await contract.cetClaimerCount();
    return {
      lastHarvestTime: user.lastHarvestTime.toString(),
      rewardLevel: user.rewardLevel.toString(),
      claimLevel: user.claimLevel.toString(),
      referralCount: user.referralCount.toString(),
      unlimitedReferrals: user.unlimitedReferrals,
      telegramId: user.telegramId,
      lastCETClaimTime: user.lastCETClaimTime.toString(),
      cetBalance: user.cetBalance.toString(),
      cetClaimers: Number(cetClaimers),
    };
  }
);

// New async thunk for fetching MTZ locked balance
export const fetchMTZLockedBalance = createAsyncThunk<
  string,
  void,
  { state: RootState }
>("metaZGame/fetchMTZLockedBalance", async (_, { getState }) => {
  const token = getState().jwt.token;
  if (!token) {
    throw new Error("Unauthorized");
  }
  try {
    const data = await fetchMTZPendingRewards(token);
    const pend = BigInt(data.totalPendingRewards * 1e18).toString();
    return pend;
  } catch (error: any) {
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data.message === "Unauthorized"
    ) {
      throw new Error("Unauthorized");
    } else {
      console.error("Error fetching pending rewards:", error);
      throw new Error("Failed to fetch pending rewards");
    }
  }
});

const metaZGameSlice = createSlice({
  name: "metaZGame",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchMTZLockedBalance.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchMTZLockedBalance.fulfilled, (state, action) => {
        state.loading = false;
        state.lockedMTZBalance = action.payload;
      })
      .addCase(fetchMTZLockedBalance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || null;
      })
      .addMatcher(
        (action) => action.type.endsWith("/pending"),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.loading = false;
          state.error = action.type || null;
        }
      );
  },
});

export default metaZGameSlice.reducer;
