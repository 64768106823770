type Props = {
  placeholder?: string;
  value: string;
  className: string;
  onChange: (value: any) => void;
  type?: string;
};

const CustomTextarea = ({
  className,
  placeholder,
  value,
  onChange,
  type = "text",
}: Props) => {
  return (
    <div className="w-full">
      <textarea
        value={value}
        onChange={onChange}
        className={`w-full  break-all p-2  ${className}`}
        placeholder={placeholder}
      />
    </div>
  );
};

export default CustomTextarea;
