import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DataContext from "../context/DataContext";
import { getUserData, logout } from "../redux/slices/userSlices";
import { triggerHapticFeedback, encryptData } from "../utils/utils";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../components/ui/alert-dialog";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { useToast } from "../components/hooks/use-toast";
import { TELEGRAM_BOT_URL } from "../components/constants/constant";
import { LockIcon, Key, ChevronRight, LogOut } from "lucide-react";

function Settings() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dataUser = useSelector(getUserData);
  const { wallet, setWallet, setBalanceToken, setButtonBackTele } =
    useContext(DataContext)!;
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);
  const [isGenerateLinkDialogOpen, setIsGenerateLinkDialogOpen] =
    useState(false);
  const [privateKeyPassword, setPrivateKeyPassword] = useState("");
  const [generatedLink, setGeneratedLink] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const { toast } = useToast();
  const version = "1.0";

  useEffect(() => {
    if (!dataUser) {
      navigate("/");
    }
    setButtonBackTele(true, onCallBackButtonBack);
  }, [dataUser]);

  const onCallBackButtonBack = () => {
    navigate("/");
  };

  const onLogout = () => {
    triggerHapticFeedback("medium");
    setIsLogoutDialogOpen(true);
  };

  const confirmLogout = () => {
    triggerHapticFeedback("medium");
    setWallet(null);
    setBalanceToken([]);
    dispatch(logout());
    setIsLogoutDialogOpen(false);
  };

  const onShowPrivateKey = () => {
    triggerHapticFeedback("medium");
    navigate("/game/backups");
  };

  const onGeneratePrivateKeyLink = () => {
    triggerHapticFeedback("medium");
    setIsGenerateLinkDialogOpen(true);
  };

  const generatePrivateKeyLink = () => {
    if (wallet && privateKeyPassword) {
      try {
        const privateKeyData = {
          privateKey: wallet.phrase,
          timestamp: Date.now(),
          version: "1.0",
        };

        const encryptedData = encryptData(
          JSON.stringify(privateKeyData),
          privateKeyPassword
        );
        const encodedData = encodeURIComponent(encryptedData);
        const link = `${TELEGRAM_BOT_URL()}private_key=${encodedData}`;

        setGeneratedLink(link);
        setIsGenerateLinkDialogOpen(false);
        triggerHapticFeedback("medium");
      } catch (error) {
        console.error("Encryption failed:", error);
        toast({
          description: "Failed to generate private key link",
        });
      }
    }
  };

  const navigateToLink = () => {
    triggerHapticFeedback("medium");
    if (generatedLink) {
      console.log("Navigating to link:", generatedLink);
      navigate(
        `/secret-key/${generatedLink
          .replace(TELEGRAM_BOT_URL(), "")
          .replace("private_key=", "")}`
      );
    }
  };

  const copyToClipboard = () => {
    triggerHapticFeedback("medium");
    if (generatedLink) {
      navigator.clipboard.writeText(generatedLink).then(() => {
        setIsCopied(true);
        triggerHapticFeedback("medium");
        setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
      });
    }
  };

  const onShareLink = () => {
    triggerHapticFeedback("medium");
    const shareMessage = `My secret phrase for wallet: ${wallet.address}: ${generatedLink}`;

    if (window.Telegram && window.Telegram.WebApp) {
      const encodedMessage = encodeURIComponent(shareMessage);
      const shareUrl = `https://t.me/share/url?url=${encodedMessage}`;

      window.Telegram.WebApp.openTelegramLink(shareUrl);
      triggerHapticFeedback("medium");
    } else {
      console.error("Telegram WebApp is not available");
    }
  };

  return (
    <div className="flex flex-col h-screen bg-white w-full">
      <div className="flex-grow p-4 overflow-y-auto">
        <div className="text-3xl font-black leading-10 mb-5">Settings</div>

        <div
          className="flex gap-2.5 justify-between p-4 w-full rounded-2xl border border-solid border-zinc-800 mb-4 cursor-pointer"
          onClick={onShowPrivateKey}
        >
          <div className="flex gap-2 items-center">
            <LockIcon size={24} />
            <div>Seed Phrase</div>
          </div>
          <ChevronRight size={24} />
        </div>

        <div
          className="flex gap-2.5 justify-between p-4 w-full rounded-2xl border border-solid border-zinc-800 mb-4 cursor-pointer"
          onClick={onGeneratePrivateKeyLink}
        >
          <div className="flex gap-2 items-center">
            <Key size={24} />
            <div>Generate Private Key Link</div>
          </div>
          <ChevronRight size={24} />
        </div>

        {generatedLink && (
          <div className="mt-4 p-4 bg-gray-100 rounded-md">
            <p className="font-bold mb-2">Your Private Key Link:</p>
            <p className="break-all">{generatedLink}</p>
            <div className="mt-4 flex gap-2 flex-wrap">
              <Button onClick={navigateToLink} className="flex-grow">
                Go to Link
              </Button>
              <Button onClick={copyToClipboard} className="flex-grow">
                {isCopied ? "Copied!" : "Copy Link"}
              </Button>
              <Button onClick={onShareLink} className="flex-grow">
                Share
              </Button>
            </div>
            <p className="mt-2 text-sm text-gray-600">
              Share this link securely. The private key can be viewed by anyone
              with this link and the password.
            </p>
          </div>
        )}
      </div>

      <div className="p-4">
        <div className="text-xl font-bold mb-2">{`Version: ${version}`}</div>
        <div
          onClick={onLogout}
          className="flex justify-center items-center px-14 py-4 text-center border border-black border-solid shadow-2xl rounded-[40px] cursor-pointer"
        >
          <div className="flex gap-2.5 items-center">
            <LogOut size={24} />
            <div>Log Out</div>
          </div>
        </div>
      </div>

      <AlertDialog
        open={isLogoutDialogOpen}
        onOpenChange={setIsLogoutDialogOpen}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              Are you sure you want to log out?
            </AlertDialogTitle>
            <AlertDialogDescription>
              Warning: If you log out without saving your secret key and
              passphrase, you will lose access to your wallet forever and won't
              be able to restore it.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={confirmLogout}>
              Log Out
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog
        open={isGenerateLinkDialogOpen}
        onOpenChange={setIsGenerateLinkDialogOpen}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Generate Private Key Link</AlertDialogTitle>
            <AlertDialogDescription>
              Enter a password to protect your private key. You'll need this
              password to view your private key later.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <Input
            type="password"
            placeholder="Enter password"
            value={privateKeyPassword}
            onChange={(e) => setPrivateKeyPassword(e.target.value)}
          />
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={generatePrivateKeyLink}>
              Generate Link
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}

export default Settings;
