import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { getUserData } from "../redux/slices/userSlices";
import useAuth from "../hooks/useAuthentication";
import Root from "../pages/Root";

const ProtectedRouter = () => {
  const userData = useSelector(getUserData);

  const initData = window.Telegram?.WebApp.initDataUnsafe;

  const navigate = useNavigate();

  useEffect(() => {
    // Check for start_param
    if (
      initData.start_param &&
      initData.start_param.startsWith("private_key=")
    ) {
      const encryptedData = initData.start_param.split("private_key=")[1];
      navigate(`/secret-key/${encryptedData}`, { replace: true });
    }
  }, [initData.start_param, navigate]);

  const hasFreePull = useMemo(() => {
    if (userData) return true;
    return false;
  }, [userData]);
  const isAuthed: boolean = useAuth();

  return isAuthed ? <Root /> : <Navigate to={"/"} replace />;
};

export default ProtectedRouter;
