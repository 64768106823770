import { ethers } from "ethers";
import {
  Balance,
  ERC20_ABI,
  CET_ADDRESS,
  Wallet,
  SwapEstimation,
  SwapResult,
} from "../constants/constants";
import { decryptTripleDES, encryptTripleDES } from "./signature";
import axios from "axios";
import { MULTICALL3_ADDRESS, RPC_URL } from "../components/constants/constant";
import CryptoJS from "crypto-js";

export const triggerHapticFeedback = (
  style: "light" | "medium" | "heavy" | "rigid" | "soft"
) => {
  if (
    window.Telegram &&
    window.Telegram.WebApp &&
    window.Telegram.WebApp.HapticFeedback
  ) {
    window.Telegram.WebApp.HapticFeedback.impactOccurred(style);
  }
};

// Multicall3 ABI (only the functions we need)
const MULTICALL3_ABI = [
  {
    inputs: [
      {
        components: [
          { internalType: "address", name: "target", type: "address" },
          { internalType: "bytes", name: "callData", type: "bytes" },
        ],
        internalType: "struct Multicall3.Call[]",
        name: "calls",
        type: "tuple[]",
      },
    ],
    name: "aggregate",
    outputs: [
      { internalType: "uint256", name: "blockNumber", type: "uint256" },
      { internalType: "bytes[]", name: "returnData", type: "bytes[]" },
    ],
    stateMutability: "payable",
    type: "function",
  },
];

export const formatShortAddress = (address: string, length: number = 4) => {
  if (!address) return "";
  const start = address.slice(0, 6);
  const end = address.slice(-length);
  return `${start}...${end}`;
};

export const newWallet = async (seedphrase: string = "") => {
  let wallet: Wallet | null = null;
  try {
    if (seedphrase === "") {
      wallet = await createNewWallet();
    } else {
      if (seedphrase.search(" ") > 0) {
        wallet = await recoverWalletFromSeedphrase(seedphrase);
      } else {
        wallet = await recoverWalletFromPrivateKey(seedphrase);
      }
    }

    return wallet;
  } catch (error) {
    return null;
  }
};

// Function to extract Ethereum address from various wallet QR formats
export function extractEthereumAddress(qrText: string) {
  // Regex to match potential Ethereum addresses
  const ethAddressRegex = /0x[a-fA-F0-9]{40}/;

  // Find the first match in the text
  const match = qrText.match(ethAddressRegex);

  // If no match is found, return null
  if (!match) {
    return null;
  }

  const address = match[0];

  // Additional check for address validity (optional)
  // This ensures that all characters after '0x' are hexadecimal
  const addressBody = address.slice(2);
  const hexRegex = /^[0-9A-Fa-f]{40}$/;

  if (!hexRegex.test(addressBody)) {
    return null;
  }

  return address;
}

export const encryptData = (data: string, password: string) => {
  const salt = CryptoJS.lib.WordArray.random(128 / 8);
  const key = CryptoJS.PBKDF2(password, salt, {
    keySize: 256 / 32,
    iterations: 1000,
  });
  const iv = CryptoJS.lib.WordArray.random(128 / 8);

  const encrypted = CryptoJS.AES.encrypt(data, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });

  // Salt, IV, and ciphertext are concatenated and base64 encoded
  const result = salt.toString() + iv.toString() + encrypted.toString();
  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(result));
};

export const decryptData = (encryptedData: string, password: string) => {
  const rawData = CryptoJS.enc.Base64.parse(encryptedData).toString(
    CryptoJS.enc.Utf8
  );

  console.log("rawData", rawData);
  const salt = CryptoJS.enc.Hex.parse(rawData.substr(0, 32));
  const iv = CryptoJS.enc.Hex.parse(rawData.substr(32, 32));
  const encrypted = rawData.substring(64);

  const key = CryptoJS.PBKDF2(password, salt, {
    keySize: 256 / 32,
    iterations: 1000,
  });

  const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
};

const createNewWallet = async (): Promise<Wallet> => {
  try {
    // Tạo một ví mới
    const wallet = ethers.Wallet.createRandom();

    // Lấy địa chỉ và private key của ví
    const address = await wallet.getAddress();
    const privateKey = wallet.privateKey;
    const seedphrase = wallet.mnemonic?.phrase ?? "";

    const data: Wallet = {
      address: address,
      privateKey: privateKey,
      phrase: seedphrase,
    };
    return data;
  } catch (error) {
    console.error("Error creating wallet:", error);
    throw error;
  }
};

// Hàm tạo lại ví từ private key
async function recoverWalletFromPrivateKey(
  privateKey: string
): Promise<Wallet> {
  try {
    const wallet = new ethers.Wallet(privateKey);
    const address = await wallet.getAddress();
    const data: Wallet = {
      address: address,
      privateKey: privateKey,
      phrase: "",
    };
    return data;
  } catch (error) {
    console.error("Error when restoring wallet from private key:", error);
    throw error;
  }
}

// Hàm tạo lại ví từ seedphrase
async function recoverWalletFromSeedphrase(
  seedphrase: string,
  path: string = "m/44'/60'/0'/0/0"
): Promise<Wallet> {
  try {
    const provider = new ethers.JsonRpcProvider(RPC_URL());
    const wallet = ethers.Wallet.fromPhrase(seedphrase, provider);
    const address = await wallet.getAddress();
    const privateKey = wallet.privateKey;
    const data: Wallet = {
      address: address,
      privateKey: privateKey,
      phrase: seedphrase,
    };
    console.log("recoverWalletFromSeedphrase", data);
    return data;
  } catch (error) {
    console.error("Error when restoring wallet from seedphrase:", error);
    throw error;
  }
}

export const formatBalance = (balance: any, decimals: number = 0) => {
  if (decimals === 0) {
    const formattedTokenBalance = ethers.formatEther(balance);
    return formattedTokenBalance;
  } else {
    const formattedTokenBalance = ethers.formatUnits(balance, decimals);
    return formattedTokenBalance;
  }
};

export const getBalanceWallet = async (
  address: string,
  lockedCETBalance: string,
  lockedMTZBalance: string,
  tokenAddresses: string[]
): Promise<Balance[]> => {
  const response: Balance[] = [];
  const provider = new ethers.JsonRpcProvider(RPC_URL());

  // Get native balance
  const nativeBalance = await provider.getBalance(address);
  response.push({
    balance: nativeBalance,
    symbol: "CET",
    address: CET_ADDRESS,
    decimals: 18,
    isNative: true,
    iconUrl: "https://www.coinex.net/images/index/coinex.png",
    isLocked: false,
  });

  if (lockedCETBalance !== "0") {
    response.push({
      balance: lockedCETBalance,
      symbol: "CET",
      address: CET_ADDRESS,
      decimals: 18,
      isNative: true,
      iconUrl: "https://www.coinex.net/images/index/coinex.png",
      isLocked: true,
    });
  }

  if (lockedMTZBalance !== "0") {
    response.push({
      balance: lockedMTZBalance,
      symbol: "MTZ",
      address: "0x3c3f2049cc1f8be5e6b4bbd9e3b5e6f8c1b3a7c9",
      decimals: 18,
      isNative: false,
      iconUrl: "https://www.coinex.net/images/index/coinex.png",
      isLocked: true,
    });
  }

  // Prepare calls for each token
  const multicall3Interface = new ethers.Interface(MULTICALL3_ABI);
  const erc20Interface = new ethers.Interface(ERC20_ABI);

  const calls = tokenAddresses.flatMap((tokenAddress) => [
    {
      target: tokenAddress,
      callData: erc20Interface.encodeFunctionData("balanceOf", [address]),
    },
    {
      target: tokenAddress,
      callData: erc20Interface.encodeFunctionData("decimals"),
    },
    {
      target: tokenAddress,
      callData: erc20Interface.encodeFunctionData("symbol"),
    },
  ]);

  // Encode the multicall
  const multicallData = multicall3Interface.encodeFunctionData("aggregate", [
    calls,
  ]);

  // Execute multicall using provider.call()
  const result = await provider.call({
    to: MULTICALL3_ADDRESS(),
    data: multicallData,
  });

  // Decode the result
  const [, returnData] = multicall3Interface.decodeFunctionResult(
    "aggregate",
    result
  );

  // Process results
  for (let i = 0; i < tokenAddresses.length; i++) {
    const tokenAddress = tokenAddresses[i];

    const balance = erc20Interface.decodeFunctionResult(
      "balanceOf",
      returnData[i * 3]
    )[0];
    const decimals = erc20Interface.decodeFunctionResult(
      "decimals",
      returnData[i * 3 + 1]
    )[0];
    const symbol = erc20Interface.decodeFunctionResult(
      "symbol",
      returnData[i * 3 + 2]
    )[0];

    response.push({
      balance: balance,
      symbol: symbol,
      address: tokenAddress,
      decimals: Number(decimals),
      isNative: false,
      iconUrl:
        "https://csc-explorer.oss-cn-hongkong.aliyuncs.com/0x978c25c94ea2cf39729bee21d041b23f69e972ac.png",
      isLocked: false,
    });
  }

  return response;
};

export const encryptWallet = (wallet: Wallet, password: string) => {
  return encryptTripleDES(String(JSON.stringify(wallet)), password);
};

export const decryptWallet = (encrypted: string, password: string) => {
  const data: Wallet = JSON.parse(decryptTripleDES(encrypted, password));

  return data;
};

export async function transferNativeCoin(
  senderPrivateKey: string,
  toAddress: string,
  amount: bigint,
  token: Balance
) {
  let response = {
    status: false,
    message: "",
    txHash: "",
    amount: amount,
    tokenSymbol: token.symbol,
    toAddress: toAddress,
    token: token,
  };
  try {
    const provider = new ethers.JsonRpcProvider(RPC_URL());
    const wallet = new ethers.Wallet(senderPrivateKey, provider);
    const balance = await provider.getBalance(wallet.address);

    // gasLimit for transfer is 21000
    const gasLimit = BigInt(21000);
    const gasPrice = ethers.parseUnits("500", "gwei");

    if (balance < amount + gasLimit * gasPrice) {
      response.message = "Insufficient native coin balance";
      return response;
    }

    const tx = await wallet.sendTransaction({
      to: toAddress,
      value: amount,
    });
    const receipt = await tx.wait();
    if (receipt) {
      response.status = true;
      response.txHash = receipt.hash;
      response.message = "Transaction successful";
    } else {
      response.message = "Transaction failed";
    }
  } catch (error) {
    console.error("Error transferring native coin:", error);
    throw error;
  }
  return response;
}

export async function transferToken(
  senderPrivateKey: string,
  toAddress: string,
  amount: bigint,
  token: Balance
) {
  let response = {
    status: false,
    message: "",
    txHash: "",
    amount: amount,
    tokenSymbol: token.symbol,
    toAddress: toAddress,
    token: token,
  };
  try {
    const provider = new ethers.JsonRpcProvider(RPC_URL());
    const wallet = new ethers.Wallet(senderPrivateKey, provider);
    const tokenContract = new ethers.Contract(token.address, ERC20_ABI, wallet);

    // Check Balance
    const balance = await tokenContract.balanceOf(wallet.address);
    if (balance < amount) {
      response.message = "Insufficient token balance";
      return response;
    }

    const tx = await tokenContract.transfer(toAddress, amount);
    const receipt = await tx.wait();
    if (receipt) {
      response.status = true;
      response.txHash = receipt.hash;
      response.message = "Transaction successful";
    } else {
      response.message = "Transaction failed";
    }
  } catch (error) {
    console.error("Error transferring token:", error);
    response.message = "Error transferring token";
    return response;
  }
  return response;
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function estimateSwapToken(
  from_contract: string,
  to_contract: string,
  from_amount: string
): Promise<SwapEstimation> {
  try {
    const params = {
      from_contract,
      to_contract,
      from_amount,
    };
    const response = await axios.get(
      "https://www.oneswap.net/res/cet/swap/router/from",
      {
        params: params,
      }
    );
    if (response.data && response.data.data) {
      return {
        init_price: response.data.data.init_price,
        path: response.data.data.path,
        from_contract: from_contract,
        to_contract: to_contract,
        from_amount: from_amount,
        to_amount: response.data.data.to_amount,
      };
    } else {
      throw new Error("Invalid response from OneSwap API");
    }
  } catch (error) {
    console.error("Error estimating swap:", error);
    throw error;
  }
}

export async function preparationSwapToken(
  contract_address: string,
  from_address: string,
  params: any
): Promise<any> {
  try {
    const data = {
      tx_type: "swapToken",
      contract_address: contract_address, // OneSwap Router address
      from_address: from_address,
      data: [
        params.address,
        params.amountIn,
        params.amountOutMin,
        params.tokenPath,
        from_address,
        params.deadline,
      ],
    };
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://www.oneswap.net/res/cet/transaction/preparation",
      headers: {
        accept: "application/json, text/plain, */*",
        "accept-language": "en_US",
        "content-type": "application/json;charset=UTF-8",
      },
      data: JSON.stringify(data),
    };

    const result = await axios.request(config);
    return result.data;
  } catch (error) {
    return null;
  }
}

export function convertToBigIntUsingEthers(
  amount: string,
  decimals: number
): bigint {
  const [integerPart, fractionalPart = ""] = amount.split(".");
  const truncatedFractionalPart = fractionalPart.slice(0, decimals - 1);
  const truncatedAmount = `${integerPart}.${truncatedFractionalPart}`;
  return ethers.parseUnits(truncatedAmount, decimals);
}

export function calculateMinimumReceived(
  amountOut: bigint,
  slippage: number
): bigint {
  const slippageFactor = BigInt(10000 - Math.floor(slippage * 100));
  const minimumReceived = (amountOut * slippageFactor) / BigInt(10000);
  return minimumReceived;
}

export function calculatePriceImpact(data: SwapEstimation) {
  // Extract relevant data from the API response
  const { init_price, from_amount, to_amount } = data;

  // Convert string values to numbers
  const initialPrice = parseFloat(init_price);
  const fromAmount = parseFloat(from_amount);
  const toAmount = parseFloat(to_amount);

  if (fromAmount === 0) return 0;

  // Calculate the effective exchange rate
  const effectiveExchangeRate = fromAmount / toAmount;

  // Calculate the price impact
  const priceImpact =
    ((effectiveExchangeRate - initialPrice) / initialPrice) * 100;

  // Return the price impact rounded to 4 decimal places
  return parseFloat(priceImpact.toFixed(4));
}

export function parseTransferLogs(
  receipt: ethers.ContractTransactionReceipt
): SwapResult {
  let result: SwapResult = {
    amountIn: BigInt(0),
    amountOut: BigInt(0),
    tokenInAddress: "",
    tokenOutAddress: "",
  };

  // ABI cho sự kiện Transfer
  const transferEventABI =
    "event Transfer(address indexed from, address indexed to, uint256 value)";
  const transferInterface = new ethers.Interface([transferEventABI]);

  // ABI cho sự kiện Swap (nếu OneSwap sử dụng sự kiện riêng)
  const swapEventABI =
    "event Swap(address indexed sender, uint256 amount0In, uint256 amount1In, uint256 amount0Out, uint256 amount1Out, address indexed to)";
  const swapInterface = new ethers.Interface([swapEventABI]);

  receipt.logs.forEach((log) => {
    try {
      // Thử parse như là sự kiện Transfer
      const parsedTransfer = transferInterface.parseLog(log);
      if (parsedTransfer) {
        if (result.tokenInAddress === "") {
          result.tokenInAddress = log.address;
          result.amountIn = parsedTransfer.args.value;
        } else {
          result.tokenOutAddress = log.address;
          result.amountOut = parsedTransfer.args.value;
        }
      }
    } catch {
      try {
        // Thử parse như là sự kiện Swap
        const parsedSwap = swapInterface.parseLog(log);
        if (parsedSwap) {
          result.amountIn =
            parsedSwap.args.amount0In > 0
              ? parsedSwap.args.amount0In
              : parsedSwap.args.amount1In;
          result.amountOut =
            parsedSwap.args.amount0Out > 0
              ? parsedSwap.args.amount0Out
              : parsedSwap.args.amount1Out;
          // Lưu ý: Trong trường hợp này, chúng ta không có thông tin về địa chỉ token
        }
      } catch {
        // Log không phải là Transfer hoặc Swap, bỏ qua
      }
    }
  });

  return result;
}

export function parseSwapLogs(
  receipt: ethers.ContractTransactionReceipt
): SwapResult {
  let result: SwapResult = {
    amountIn: BigInt(0),
    amountOut: BigInt(0),
    tokenInAddress: "",
    tokenOutAddress: "",
  };

  // ABI cho sự kiện Transfer
  const transferEventABI =
    "event Transfer(address indexed from, address indexed to, uint256 value)";
  const transferInterface = new ethers.Interface([transferEventABI]);

  // ABI cho sự kiện Swap (nếu OneSwap sử dụng sự kiện riêng)
  const swapEventABI =
    "event Swap(address indexed sender, uint256 amount0In, uint256 amount1In, uint256 amount0Out, uint256 amount1Out, address indexed to)";
  const swapInterface = new ethers.Interface([swapEventABI]);

  receipt.logs.forEach((log) => {
    try {
      // Thử parse như là sự kiện Transfer
      const parsedTransfer = transferInterface.parseLog(log);
      if (parsedTransfer) {
        if (result.tokenInAddress === "") {
          result.tokenInAddress = log.address;
          result.amountIn = parsedTransfer.args.value;
        } else {
          result.tokenOutAddress = log.address;
          result.amountOut = parsedTransfer.args.value;
        }
      }
    } catch {
      try {
        // Thử parse như là sự kiện Swap
        const parsedSwap = swapInterface.parseLog(log);
        if (parsedSwap) {
          result.amountIn =
            parsedSwap.args.amount0In > 0
              ? parsedSwap.args.amount0In
              : parsedSwap.args.amount1In;
          result.amountOut =
            parsedSwap.args.amount0Out > 0
              ? parsedSwap.args.amount0Out
              : parsedSwap.args.amount1Out;
          // Lưu ý: Trong trường hợp này, chúng ta không có thông tin về địa chỉ token
        }
      } catch {
        // Log không phải là Transfer hoặc Swap, bỏ qua
      }
    }
  });

  return result;
}

export function formatDecimalString(
  value: string,
  decimalPlaces: number = 6
): string {
  const cleanValue = value.replace(/[^\d.]/g, "");
  const number = parseFloat(cleanValue);
  if (isNaN(number)) {
    return "Invalid number";
  }
  const formattedNumber = number.toFixed(decimalPlaces);
  return formattedNumber.replace(/\.?0+$/, "");
}
