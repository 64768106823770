import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ethers } from "ethers";
import { useSelector } from "react-redux";
import { useToast } from "../components/hooks/use-toast";

import Button from "../components/Button";
import DataContext from "../context/DataContext";
import { getUserData } from "../redux/slices/userSlices";
import {
  Balance,
  ERC20_ABI,
  RPC_URL,
  SWAP_ROUTER_ADDRESS,
  SwapEstimation,
} from "../constants/constants";
import {
  calculateMinimumReceived,
  calculatePriceImpact,
  convertToBigIntUsingEthers,
  estimateSwapToken,
  formatBalance,
  formatDecimalString,
  parseSwapLogs,
  triggerHapticFeedback,
} from "../utils/utils";
import ChooseToken from "../components/ChooseToken";
import SettingSwap from "../components/SettingSwap";
import AmountInput from "../components/AmountInput";
import contact_abi from "../constants/contract_abi.json";
import SwapResult from "../components/SwapResult";
import { Card, CardContent } from "../components/ui/card";
import { Settings, ChevronDown, RefreshCw } from "lucide-react";

// Assets
import icon_cet from "../assets/icon-cet.png";

import * as _ from "lodash";

interface FormData {
  token: Balance | null;
  amount: bigint;
  receiver: string;
}

interface TokenDataWithBalance extends FormData {
  balance: bigint;
}

function Swap() {
  const navigate = useNavigate();
  const dataUser = useSelector(getUserData);
  const { userTele, wallet, balanceToken, setButtonBackTele, getBalanceToken } =
    useContext(DataContext)!;
  const { toast } = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [isShowChoose, setIsShowChoose] = useState(false);
  const [isShowSettings, setIsShowSettings] = useState(false);
  const [srcSelect, setSrcSelect] = useState("A");
  const [slippage, setSlippage] = useState(0.5);
  const [result, setResult] = useState<any>(null);
  const [perAmount, setPerAmount] = useState(50);
  const [shouldEstimate, setShouldEstimate] = useState(false);
  const [minimumReceived, setMinimumReceived] = useState(BigInt(0));
  const [dataEstimate, setDataEstimate] = useState<SwapEstimation | null>(null);
  const [priceImpact, setPriceImpact] = useState(0);

  const [dataA, setDataA] = useState<TokenDataWithBalance>({
    token: null,
    amount: BigInt(0),
    balance: BigInt(0),
    receiver: "",
  });
  const [dataB, setDataB] = useState<TokenDataWithBalance>({
    token: null,
    amount: BigInt(0),
    balance: BigInt(0),
    receiver: "",
  });

  const debouncedEstimate = useCallback(
    _.debounce(() => {
      setShouldEstimate(true);
    }, 500),
    []
  );

  useEffect(() => {
    if (!dataUser) {
      navigate("/");
    }
    setButtonBackTele(true, () => navigate("/"));
  }, [dataUser, navigate, setButtonBackTele]);

  useEffect(() => {
    if (
      balanceToken &&
      balanceToken.length > 0 &&
      !dataA.token &&
      !dataB.token
    ) {
      const newAmount =
        (balanceToken[0].balance * BigInt(perAmount)) / BigInt(100);
      setDataA({
        token: balanceToken[0],
        amount: newAmount,
        balance: balanceToken[0].balance,
        receiver: "",
      });
      setDataB({
        token: balanceToken[balanceToken.length - 1],
        amount: BigInt(0),
        balance: balanceToken[balanceToken.length - 1].balance,
        receiver: "",
      });
    }
  }, [balanceToken]);

  useEffect(() => {
    if (dataA.token && dataB.token && dataA.amount > BigInt(0)) {
      debouncedEstimate();
    }
  }, [dataA.token, dataB.token, dataA.amount, slippage, debouncedEstimate]);

  useEffect(() => {
    if (shouldEstimate) {
      estimateSwap();
    }
  }, [shouldEstimate]);

  const handleAmountChange = (newAmount: bigint) => {
    setDataA((prev) => ({ ...prev, amount: newAmount }));
    debouncedEstimate();
  };

  const estimateSwap = async () => {
    setShouldEstimate(false);
    if (!dataA.token || !dataB.token) return;
    try {
      const amountIn = ethers.formatUnits(dataA.amount, dataA.token.decimals);
      const result = await estimateSwapToken(
        dataA.token.address,
        dataB.token.address,
        amountIn
      );
      if (result) {
        setDataEstimate(result);
        const amountOut = convertToBigIntUsingEthers(
          result.to_amount,
          dataB.token.decimals
        );
        setDataB((prev) => ({
          ...prev,
          amount: amountOut,
        }));
        setMinimumReceived(calculateMinimumReceived(amountOut, slippage));
        setPriceImpact(calculatePriceImpact(result));
      }
    } catch (error) {
      console.error("Estimate swap error:", error);
      toast({
        variant: "destructive",
        description: "Failed to estimate swap",
      });
    } finally {
      setShouldEstimate(false);
    }
  };

  const handleSwap = async () => {
    triggerHapticFeedback("medium");
    if (dataA.amount === BigInt(0)) {
      toast({
        variant: "destructive",
        description: "Please enter an amount to swap",
      });
      return;
    }

    setIsLoading(true);
    try {
      if (dataEstimate && dataA.token) {
        const provider = new ethers.JsonRpcProvider(RPC_URL);
        const signer = new ethers.Wallet(wallet.privateKey, provider);
        const router = new ethers.Contract(
          SWAP_ROUTER_ADDRESS,
          contact_abi,
          signer
        );

        const amountIn = ethers.parseUnits(
          dataEstimate.from_amount,
          dataA.token.decimals
        );
        const amountOut = convertToBigIntUsingEthers(
          dataEstimate.to_amount,
          dataB.token!.decimals
        );
        const amountOutMin = calculateMinimumReceived(amountOut, slippage);

        const tokenPath = dataEstimate.path.map(
          (item: any) => item.market_contract
        );
        const to = await signer.getAddress();
        const deadline = Math.floor(Date.now() / 1000) + 60 * 20; // 20 minutes from now

        let txParams: any = { gasLimit: 500000 };
        if (dataA.token.address === ethers.ZeroAddress) {
          txParams.value = amountIn;
        } else {
          await approveToken(signer, dataA.token.address, amountIn);
        }

        const estimatedGas = await router.swapToken.estimateGas(
          dataA.token.address,
          amountIn.toString(),
          amountOutMin.toString(),
          tokenPath,
          to,
          deadline,
          txParams
        );

        txParams.gasLimit = estimatedGas;

        const balance = await provider.getBalance(to);
        const gasPrice = ethers.parseUnits("500", "gwei");
        const totalCost =
          estimatedGas * gasPrice +
          (dataA.token.address === ethers.ZeroAddress
            ? txParams.value
            : BigInt(0));

        if (totalCost > balance) {
          throw new Error("Insufficient balance to pay for gas");
        }

        const tx = await router.swapToken(
          dataA.token.address,
          amountIn.toString(),
          amountOutMin.toString(),
          tokenPath,
          to,
          deadline,
          txParams
        );

        const receipt = await tx.wait();
        const resultParse = parseSwapLogs(receipt);

        setResult({
          status: true,
          message: "Swap completed",
          txHash: receipt.hash,
          toAddress: wallet.address,
          dataA: dataA,
          dataB: dataB,
          amountIn: resultParse.amountIn,
          amountOut: resultParse.amountOut,
        });
      }
    } catch (error: any) {
      toast({
        variant: "destructive",
        description: error.message,
      });
    } finally {
      setIsLoading(false);
      getBalanceToken();
    }
  };

  const approveToken = async (
    signer: ethers.Wallet,
    tokenAddress: string,
    amount: bigint
  ) => {
    const tokenContract = new ethers.Contract(tokenAddress, ERC20_ABI, signer);
    const allowance = await tokenContract.allowance(
      await signer.getAddress(),
      SWAP_ROUTER_ADDRESS
    );
    if (allowance < amount) {
      const gasLimit = await tokenContract.approve.estimateGas(
        SWAP_ROUTER_ADDRESS,
        ethers.MaxUint256
      );
      const approveTx = await tokenContract.approve(
        SWAP_ROUTER_ADDRESS,
        ethers.MaxUint256,
        { gasLimit }
      );
      await approveTx.wait();
    }
  };

  const onClickItemToken = (data: Balance) => {
    triggerHapticFeedback("medium");
    setIsShowChoose(false);
    if (srcSelect === "A") {
      setDataA({ ...dataA, token: data, balance: data.balance });
    } else {
      setDataB({ ...dataB, token: data, balance: data.balance });
    }
  };

  const onShowSelectToken = (source: string = "") => {
    triggerHapticFeedback("medium");
    setSrcSelect(source);
    setIsShowChoose(source !== "");
  };

  const onChangeAtoB = () => {
    triggerHapticFeedback("medium");
    const tempA = { ...dataA };
    const amountANew =
      dataB.amount > dataB.balance ? dataB.balance : dataB.amount;
    setDataA({ ...dataB, amount: amountANew });
    setDataB({ ...tempA, amount: BigInt(0) });
  };

  const onShowSettings = () => {
    triggerHapticFeedback("medium");
    setIsShowSettings(true);
  };

  const onCloseSettings = (newSlippage: number) => {
    triggerHapticFeedback("medium");
    setIsShowSettings(false);
    setSlippage(newSlippage);
  };

  const onChangeAmountTokenAFast = (percentage: number) => {
    setPerAmount(percentage);
    if (dataA.token && dataA.balance) {
      const newAmount = (dataA.balance * BigInt(percentage)) / BigInt(100);
      setDataA({ ...dataA, amount: newAmount });
    }
  };

  const onGoHomeFromResult = () => {
    triggerHapticFeedback("medium");
    setResult(null);
  };

  if (!wallet) return null;

  if (result) {
    return <SwapResult result={result} onClose={onGoHomeFromResult} />;
  }

  return (
    <div className="flex flex-col pb-20 mx-auto w-full text-base leading-6 max-w-[480px] bg-gray-100 min-h-screen">
      <Card className="m-4 shadow-lg">
        <CardContent className="p-6">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-3xl font-black text-zinc-800">Swap</h1>
            <Settings
              className="w-6 h-6 text-gray-600 cursor-pointer"
              onClick={onShowSettings}
            />
          </div>
          <p className="text-gray-600 mb-6">Trade token in an instant</p>

          <SwapSection
            title="From"
            data={dataA}
            setData={(newData) => setDataA((prev) => ({ ...prev, ...newData }))}
            onShowSelectToken={() => onShowSelectToken("A")}
            perAmount={perAmount}
            onChangeAmountTokenAFast={onChangeAmountTokenAFast}
            onAmountChange={handleAmountChange}
          />

          <div className="flex justify-center my-4">
            <Card
              className="p-2 rounded-full shadow-md cursor-pointer"
              onClick={onChangeAtoB}
            >
              <RefreshCw className="w-6 h-6 text-gray-600" />
            </Card>
          </div>

          <SwapSection
            title="To"
            data={dataB}
            setData={(newData) => setDataB((prev) => ({ ...prev, ...newData }))}
            onShowSelectToken={() => onShowSelectToken("B")}
            onAmountChange={handleAmountChange}
          />

          <SwapDetails
            priceImpact={priceImpact}
            minimumReceived={minimumReceived}
            slippage={slippage}
            dataB={dataB}
          />

          <Button
            isLoading={isLoading}
            className="w-full mt-6"
            label="Swap"
            handleClick={handleSwap}
          />
        </CardContent>
      </Card>

      <ChooseToken
        isShowChoose={isShowChoose}
        onClickItemToken={onClickItemToken}
        onShowSelectToken={onShowSelectToken}
      />
      <SettingSwap
        isShowSettings={isShowSettings}
        onClose={onCloseSettings}
        defaultPer={slippage}
      />
    </div>
  );
}

interface SwapSectionProps {
  title: string;
  data: FormData;
  setData: React.Dispatch<React.SetStateAction<FormData>>;
  onShowSelectToken: () => void;
  perAmount?: number;
  onChangeAmountTokenAFast?: (percentage: number) => void;
  onAmountChange: (newAmount: bigint) => void;
}

function SwapSection({
  title,
  data,
  setData,
  onShowSelectToken,
  perAmount,
  onChangeAmountTokenAFast,
  onAmountChange,
}: SwapSectionProps) {
  return (
    <Card className="mb-4 shadow-md">
      <CardContent className="p-4">
        <h2 className="font-bold text-zinc-800 mb-2">{title}</h2>
        <div className="flex gap-2 items-center mb-2">
          <div className="flex-1">
            <AmountInput
              formData={data}
              setFormData={setData}
              tokenSelected={data.token}
              onAmountChange={onAmountChange}
            />
          </div>
          {data.token && (
            <Card
              className="shadow-sm cursor-pointer"
              onClick={onShowSelectToken}
            >
              <CardContent className="p-2 flex items-center gap-2">
                <img
                  src={data.token.iconUrl || icon_cet}
                  className="w-6 h-6"
                  alt={data.token.symbol}
                />
                <span className="font-semibold">{data.token.symbol}</span>
                <ChevronDown size={16} className="text-gray-500" />
              </CardContent>
            </Card>
          )}
        </div>
        {title === "From" &&
          perAmount !== undefined &&
          onChangeAmountTokenAFast && (
            <PercentageSelector
              perAmount={perAmount}
              onChangeAmountTokenAFast={onChangeAmountTokenAFast}
            />
          )}
        <div className="text-sm text-gray-600">
          Balance:{" "}
          {data.token
            ? formatBalance(data.token.balance, data.token.decimals) +
              " " +
              data.token.symbol
            : ""}
        </div>
      </CardContent>
    </Card>
  );
}

interface PercentageSelectorProps {
  perAmount: number;
  onChangeAmountTokenAFast: (percentage: number) => void;
}

function PercentageSelector({
  perAmount,
  onChangeAmountTokenAFast,
}: PercentageSelectorProps) {
  const percentages = [25, 50, 75, 100];
  return (
    <Card className="shadow-sm mb-2">
      <CardContent className="p-1">
        <div className="flex justify-between">
          {percentages.map((percentage) => (
            <div
              key={percentage}
              className={`flex-1 py-1 text-center cursor-pointer transition-colors duration-200 ${
                perAmount === percentage
                  ? "bg-[#FEBB56] text-white rounded-full"
                  : "text-gray-500 hover:bg-gray-100 rounded-full"
              }`}
              onClick={() => onChangeAmountTokenAFast(percentage)}
            >
              {percentage === 100 ? "MAX" : `${percentage}%`}
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
}

interface SwapDetailsProps {
  priceImpact: number;
  minimumReceived: bigint;
  slippage: number;
  dataB: FormData;
}

function SwapDetails({
  priceImpact,
  minimumReceived,
  slippage,
  dataB,
}: SwapDetailsProps) {
  return (
    <Card className="mt-4 shadow-md">
      <CardContent className="p-4">
        <div className="flex justify-between mb-2">
          <span className="font-bold text-zinc-800">Price impact:</span>
          <span className="text-gray-600">{priceImpact}%</span>
        </div>
        {dataB.token && (
          <div className="flex justify-between mb-2">
            <span className="font-bold text-zinc-800">Minimum Received:</span>
            <span className="text-gray-600">
              {formatDecimalString(
                formatBalance(minimumReceived, dataB.token.decimals),
                8
              )}{" "}
              <b>{dataB.token.symbol}</b>
            </span>
          </div>
        )}
        <div className="flex justify-between">
          <span className="font-bold text-zinc-800">Slippage Tolerance:</span>
          <span className="text-gray-600">{slippage}%</span>
        </div>
      </CardContent>
    </Card>
  );
}

export default Swap;
