type Props = {
  label: string;
  className: string;
  handleClick: () => void;
  isLoading?: boolean;
};
const Button = ({
  className,
  label,
  handleClick,
  isLoading = false,
}: Props) => {
  return (
    <>
      {isLoading ? (
        <div
          className={`gap-2.5 self-stretch px-14 py-4 mt-4 text-base font-semibold leading-none text-center text-black bg-[#fdb357] border border-solid border-zinc-800 rounded-[10000px] ${className}`}
        >
          Loading...
        </div>
      ) : (
        <div
          onClick={handleClick}
          className={`gap-2.5 self-stretch px-14 py-4 mt-4 text-base font-semibold leading-none text-center text-black bg-[#fdb357] border border-solid border-zinc-800 rounded-[10000px] ${className}`}
        >
          {label}
        </div>
      )}
    </>
  );
};

export default Button;
