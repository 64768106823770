import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ethers } from "ethers";
import { RootState, useAppDispatch } from "../redux/store";
import { getUserData } from "../redux/slices/userSlices";
import {
  fetchUser,
  harvest,
  upgradeClaimLevel,
  upgradeRewardLevel,
} from "../redux/slices/metazGameSlice";
import DataContext from "../context/DataContext";
import { useToast } from "../components/hooks/use-toast";
import { linkAccount } from "../auth/telegramAuth";
import {
  formatBalance,
  formatDecimalString,
  triggerHapticFeedback,
} from "../utils/utils";

// Components
import PopupUpgradeBackground from "../components/PopupUpgradeBackground";
import PopupUpgradeCAT from "../components/PopupUpgradeCAT";
import LoadingScreen from "../components/LoadingScreen";

// Asset imports
import bglv1 from "../assets/BGlv1.png";
import bglv2 from "../assets/BGlv2.png";
import bglv3 from "../assets/BGlv3.png";
import bglv4 from "../assets/BGlv4.png";
import bglv5 from "../assets/BGlv5.png";
import bglv6 from "../assets/BGlv6.png";
import nft1 from "../assets/nft1.png";
import nft2 from "../assets/nft2.png";
import nft3 from "../assets/nft3.png";
import nft4 from "../assets/nft4.png";
import nft5 from "../assets/nft5.png";
import nft6 from "../assets/nft6.png";
import icon_METAZ_white from "../assets/icon-METAZ-white.svg";
import icon_mission from "../assets/icon-mission.svg";
import btn_claim from "../assets/btn-claim.png";
import label_scenery from "../assets/label-scenery.png";
import label_earn from "../assets/label-earn.png";
import btn_levelup from "../assets/btn-levelup.svg";

// Constants
const BASE_CLAIM_INTERVAL = 2 * 60 * 60; // 2 hours in seconds
const REWARD_AMOUNTS = [1e16, 1e16, 1e17, 2e17, 3e17, 4e17, 10e18];
const CLAIM_INTERVALS = [2, 2, 4, 6, 8, 12, 24].map((hours) => hours * 60 * 60);

const GameHome: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { wallet, setButtonBackTele, balanceToken } = useContext(DataContext)!;
  const jwt = useSelector((state: RootState) => state.jwt.token);
  const dataUser = useSelector(getUserData);
  const { user } = useSelector((state: RootState) => state.metaZGame);

  const [isShowPopupUpgradeScenery, setIsShowPopupUpgradeScenery] =
    useState(false);
  const [isShowPopupUpgradeTaz, setIsShowPopupUpgradeTaz] = useState(false);
  const [pendingReward, setPendingReward] = useState("Loading...");
  const [currentRewardPerHour, setCurrentRewardPerHour] =
    useState("Loading...");
  const [currentMaxCapacity, setCurrentMaxCapacity] = useState("Loading...");
  const [interactionState, setInteractionState] = useState<
    "idle" | "loading" | "success" | "failure"
  >("idle");
  const [interactionProgress, setInteractionProgress] = useState(0);
  const [errorMess, setErrorMess] = useState("");
  const [timeRemaining, setTimeRemaining] = useState<number | null>(null);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const interval = setInterval(updateRewardAndTimer, 1000);
    return () => clearInterval(interval);
  }, [user]);

  const updateRewardAndTimer = () => {
    if (!user || user.lastHarvestTime === "0") {
      setPendingReward("0.01");
      setTimeRemaining(null);
      return;
    }

    const now = Date.now() / 1000;
    const claimInterval = CLAIM_INTERVALS[Number(user.claimLevel)];
    const timeSinceLastHarvest = Math.floor(now - Number(user.lastHarvestTime));

    if (timeSinceLastHarvest >= claimInterval) {
      const reward =
        REWARD_AMOUNTS[Number(user.rewardLevel)] *
        (claimInterval / BASE_CLAIM_INTERVAL);
      setPendingReward((reward / 1e18).toFixed(4).toString());
      setTimeRemaining(0);
    } else {
      const reward =
        (REWARD_AMOUNTS[Number(user.rewardLevel)] *
          timeSinceLastHarvest *
          (claimInterval / BASE_CLAIM_INTERVAL)) /
        claimInterval;
      setPendingReward((reward / 1e18).toFixed(4).toString());
      setTimeRemaining(claimInterval - timeSinceLastHarvest);
    }
  };

  const formatTime = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
  };

  useEffect(() => {
    if (!dataUser) {
      navigate("/");
    }
    setButtonBackTele(true, () => navigate("/"));
  }, [dataUser, navigate, setButtonBackTele]);

  useEffect(() => {
    if (!user) {
      dispatch(fetchUser());
    } else {
      const reward = BigInt(REWARD_AMOUNTS[Number(user?.rewardLevel)]);
      const capacity = CLAIM_INTERVALS[Number(user?.claimLevel)] / 3600;
      setCurrentRewardPerHour(ethers.formatEther(reward));
      setCurrentMaxCapacity(capacity.toString());
    }
  }, [dispatch, user]);

  useEffect(() => {
    const interval = setInterval(calculatePendingReward, 1000);
    return () => clearInterval(interval);
  }, [user]);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const calculatePendingReward = () => {
    if (user?.lastHarvestTime === "0") {
      setPendingReward("0.01");
      return;
    }
    const now = Date.now() / 1000;
    const claimInterval = CLAIM_INTERVALS[Number(user?.claimLevel)];
    const timeSinceLastHarvest = Math.floor(
      now - Number(user?.lastHarvestTime)
    );

    let reward;
    if (timeSinceLastHarvest >= claimInterval) {
      reward =
        REWARD_AMOUNTS[Number(user?.rewardLevel)] *
        (claimInterval / BASE_CLAIM_INTERVAL);
    } else {
      reward =
        (REWARD_AMOUNTS[Number(user?.rewardLevel)] *
          timeSinceLastHarvest *
          (claimInterval / BASE_CLAIM_INTERVAL)) /
        claimInterval;
    }

    setPendingReward((reward / 1e18).toFixed(4).toString());
  };

  const getBGByLevel = (level: number) => {
    const backgrounds = [bglv1, bglv1, bglv2, bglv3, bglv4, bglv5, bglv6];
    return backgrounds[Math.min(level, backgrounds.length - 1)];
  };

  const getNFTByLevel = (level: number) => {
    const nfts = [nft1, nft1, nft2, nft3, nft4, nft5, nft6];
    return nfts[Math.min(level, nfts.length - 1)];
  };

  const handleClaim = async () => {
    triggerHapticFeedback("medium");
    setInteractionState("loading");
    setInteractionProgress(0);

    if (!jwt) {
      toast({
        description: "Error occurred, please reload this page and try again",
        duration: 3000,
        variant: "destructive",
      });
      return;
    }

    try {
      if (user?.lastHarvestTime === "0") {
        await handleFirstClaim();
      } else {
        await handleRegularClaim();
      }
    } catch (error: any) {
      console.error("Error during claim process:", error);
      setErrorMess(error.message);
      setInteractionState("failure");
    }
  };

  const handleFirstClaim = async () => {
    if (!jwt) {
      toast({
        description: "Error occurred, please reload this page and try again",
        duration: 3000,
        variant: "destructive",
      });
      return;
    }
    for (let i = 0; i <= 80; i += 10) {
      setInteractionProgress(i);
      await new Promise((resolve) => setTimeout(resolve, 200));
    }

    if (window.Telegram.WebApp.initDataUnsafe.start_param) {
      const refId = window.Telegram.WebApp.initDataUnsafe.start_param.replace(
        "ref_",
        ""
      );
      await linkAccount(wallet.address, jwt, refId);
    } else {
      await linkAccount(wallet.address, jwt);
    }

    let isJobFinished = false;
    const timeout = setTimeout(() => {
      setErrorMess(
        "Operation timed out. Please refresh the page and try again."
      );
      setInteractionState("success");
      isJobFinished = true;
    }, 30000);

    while (!isJobFinished) {
      try {
        await dispatch(fetchUser());
        if (user?.lastHarvestTime !== "0") {
          isJobFinished = true;
          clearTimeout(timeout);
        }
        await new Promise((resolve) => setTimeout(resolve, 1000));
      } catch (error: any) {
        console.error("Error during claim process:", error);
        setErrorMess(
          "There is an error during the claim process, please try again later!"
        );
        setInteractionState("failure");
        clearTimeout(timeout);
        isJobFinished = true;
      }
    }

    for (let i = 80; i <= 100; i += 10) {
      setInteractionProgress(i);
      await new Promise((resolve) => setTimeout(resolve, 200));
    }
  };

  const handleRegularClaim = async () => {
    for (let i = 0; i <= 50; i += 10) {
      setInteractionProgress(i);
      await new Promise((resolve) => setTimeout(resolve, 200));
    }

    await dispatch(harvest()).unwrap();

    for (let i = 60; i <= 100; i += 10) {
      setInteractionProgress(i);
      await new Promise((resolve) => setTimeout(resolve, 200));
    }

    await dispatch(fetchUser());

    setInteractionState("success");
    toast({ description: "Harvest successful!", duration: 3000 });
  };

  const handleUpgrade = async (upgradeType: "claim" | "reward") => {
    triggerHapticFeedback("medium");
    setInteractionState("loading");
    setInteractionProgress(0);

    const level =
      upgradeType === "claim" ? user?.claimLevel : user?.rewardLevel;
    const maxLevel = 6;

    if (Number(level) === maxLevel) {
      toast({
        description: "You are already at the maximum level",
        variant: "destructive",
        duration: 3000,
      });
      setInteractionProgress(100);
      setInteractionState("success");
      return;
    }

    if (level === "0") {
      toast({
        description: "You need to claim your first reward before upgrading",
        variant: "destructive",
        duration: 3000,
      });
      setInteractionProgress(100);
      setInteractionState("success");
      return;
    }

    try {
      for (let i = 0; i <= 50; i += 10) {
        setInteractionProgress(i);
        await new Promise((resolve) => setTimeout(resolve, 200));
      }

      await dispatch(
        upgradeType === "claim" ? upgradeClaimLevel() : upgradeRewardLevel()
      ).unwrap();

      for (let i = 60; i <= 100; i += 10) {
        setInteractionProgress(i);
        await new Promise((resolve) => setTimeout(resolve, 200));
      }

      await dispatch(fetchUser());

      setInteractionState("success");
      toast({ description: "Upgrade successful!", duration: 3000 });
    } catch (error: any) {
      console.error("Error during upgrade process:", error);
      setErrorMess(error.message);
      setInteractionState("failure");
    }
  };

  return (
    <div
      ref={containerRef}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: "hidden",
        backgroundImage: `url(${
          user ? getBGByLevel(Number(user.claimLevel)) : bglv1
        })`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "16px",
        boxSizing: "border-box",
      }}
    >
      <canvas ref={canvasRef} style={{ display: "none" }} />

      {/* Top bar */}
      <div className="flex justify-between items-start w-full absolute top-0 left-0 right-0 p-4">
        <div className="flex items-center bg-gradient-to-b from-[#A36841] to-[#63433B] rounded-full px-3 py-2">
          <img src={icon_METAZ_white} alt="METAZ" className="w-6 h-6 mr-2" />
          <span className="text-white font-bold">
            {balanceToken && balanceToken.length > 0
              ? formatDecimalString(
                  formatBalance(
                    balanceToken[0].balance,
                    balanceToken[0].decimals
                  ),
                  8
                )
              : 0}
          </span>
        </div>
        <div
          onClick={() => {
            triggerHapticFeedback("medium");
            navigate("/game/mission");
          }}
          className="flex items-center bg-gradient-to-b from-[#A36841] to-[#63433B] rounded-full px-3 py-2"
        >
          <img src={icon_mission} alt="Mission" className="w-6 h-6 mr-2" />
          <span className="text-white font-bold">Mission</span>
        </div>
      </div>

      {/* Main content */}
      <div className="flex flex-col items-center justify-center flex-grow">
        <img
          src={user ? getNFTByLevel(Number(user.rewardLevel)) : ""}
          alt="NFT"
          className="w-48 h-48 object-contain"
        />
        <div className="mt-4 bg-gradient-to-b from-[#A36841] to-[#63433B] rounded-full px-4 py-2">
          <span className="text-white font-bold text-2xl">{pendingReward}</span>
        </div>
        {timeRemaining === 0 || user?.lastHarvestTime === "0" ? (
          <img
            src={btn_claim}
            alt="Claim"
            onClick={handleClaim}
            className="mt-4 w-24 cursor-pointer"
          />
        ) : (
          <div className="mt-4 bg-gradient-to-b from-[#A36841] to-[#63433B] rounded-full px-4 py-2">
            <span className="text-white font-bold">
              {timeRemaining !== null
                ? formatTime(timeRemaining)
                : "Loading..."}
            </span>
          </div>
        )}
      </div>

      {/* Bottom section */}
      <div className="flex justify-between w-full max-w-md mx-auto">
        <div className="flex flex-col items-center">
          <img src={label_scenery} alt="Scenery" className="mb-2" />
          <div className="bg-white rounded-xl border-4 border-yellow-950 p-2 text-center">
            <span className="font-bold">{currentMaxCapacity} Hours</span>
          </div>
          <img
            src={btn_levelup}
            alt="Level Up"
            onClick={() => {
              triggerHapticFeedback("light");
              setIsShowPopupUpgradeScenery(true);
            }}
            className="mt-2 w-24 cursor-pointer"
          />
        </div>
        <div className="flex flex-col items-center">
          <img src={label_earn} alt="Earn" className="mb-2" />
          <div className="bg-white rounded-xl border-4 border-yellow-950 p-2 text-center">
            <span className="font-bold">
              {currentRewardPerHour} {user?.rewardLevel === "6" ? "CET" : "MTZ"}{" "}
              / 2h
            </span>
          </div>
          <img
            src={btn_levelup}
            alt="Level Up"
            onClick={() => {
              triggerHapticFeedback("light");
              setIsShowPopupUpgradeTaz(true);
            }}
            className="mt-2 w-24 cursor-pointer"
          />
        </div>
      </div>

      <LoadingScreen
        state={interactionState}
        progress={interactionProgress}
        errorMesssage={errorMess}
        onClose={() => {
          triggerHapticFeedback("light");
          setInteractionState("idle");
          setInteractionProgress(0);
        }}
      />
      <PopupUpgradeBackground
        isShowPopup={isShowPopupUpgradeScenery}
        onClose={() => {
          triggerHapticFeedback("light");
          setIsShowPopupUpgradeScenery(false);
        }}
        currentClaimLevel={
          user ? Number(user.claimLevel === "0" ? 1 : user.claimLevel) : 1
        }
        onUpgrade={() => handleUpgrade("claim")}
      />
      <PopupUpgradeCAT
        isShowPopup={isShowPopupUpgradeTaz}
        onClose={() => {
          triggerHapticFeedback("light");
          setIsShowPopupUpgradeTaz(false);
        }}
        currentRewardLevel={
          user ? Number(user.rewardLevel === "0" ? 1 : user.rewardLevel) : 1
        }
        onUpgrade={() => handleUpgrade("reward")}
      />
    </div>
  );
};

export default GameHome;
