import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { destroyNotification } from "../../redux/slices/notificationSlices";

const TIME_OUT = 5000;

export default function Notification() {
  const { isOpen, content, title, type, onClickConfirm } = useSelector(
    (state: any) => state.notification
  );
  const pathname = useLocation().pathname;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(destroyNotification());
  }, [dispatch, pathname]);

  useEffect(() => {
    if (isOpen && type !== "confirm") {
      setTimeout(() => {
        dispatch(destroyNotification());
      }, TIME_OUT);
    }
  }, [dispatch, isOpen, type]);

  if (!isOpen) return null;
  if (type === "failure") {
    return (
      <>
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-85 z-[10001]"></div>
        <div
          className={`font-seymour text-white modal-container w-[90%] flex justify-center absolute z-[10001] top-[50%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-opacity-40 opacity-0 transition-opacity duration-500 ${
            isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
          }`}
        >
          <div className="container flex flex-col justify-between items-center gap-1">
            {/* title */}
            {title && (
              <div className="gradient-box-shadow-gray title text-[2.5rem] text-center font-seymour-border text-[#C2C2C2]">
                <div>YOU LOSE</div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className=" absolute top-0 left-0 w-full h-full bg-black opacity-85 z-[10001]"></div>
      <div
        className={`font-seymour text-white modal-container w-[90%] flex justify-center absolute z-[10001] top-[40%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-opacity-40 opacity-0 transition-opacity duration-500 ${
          isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
      >
        {type === "success" ? (
          <>
            <div className="container flex flex-col justify-between items-center gap-3">
              {/* title */}
              {title && (
                <div className="bg-none !bg-transparent title text-[2rem] text-center font-seymour-border-orange text-[#FFCA10]">
                  {title}
                </div>
              )}
              {/* content */}
              <div className="flex justify-center items-center px-3 w-full h-full overflow-auto no-scrollbar">
                {content}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="container justify-between items-center gap-1">
              <div className="flex flex-col gap-1 justify-center items-center px-3 w-full h-full overflow-auto no-scrollbar">
                <div>{content}</div>
                {/* button */}
                <div className="flex gap-3">
                  <div
                    onClick={() => dispatch(destroyNotification())}
                    className="min-w-[95px] hover:cursor-pointer text-lg py-[16px] px-[24px] bg-[#858585] rounded-full border-gray-300 border-2"
                  >
                    <div className="px-3">CANCEL</div>
                  </div>
                  <div
                    onClick={() => {
                      dispatch(destroyNotification());
                      onClickConfirm();
                    }}
                    className="min-w-[95px] hover:cursor-pointer text-lg py-[16px] px-[24px] bg-[#18AA00] rounded-full border-green-300 border-2"
                  >
                    <div className="px-3">OK</div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
