import React, { useEffect, useState } from "react";
import { ethers } from "ethers";

import main_lv1 from "../assets/BGlv1.png";
import main_lv2 from "../assets/BGlv2.png";
import main_lv3 from "../assets/BGlv3.png";
import main_lv4 from "../assets/BGlv4.png";
import main_lv5 from "../assets/BGlv5.png";
import main_lv6 from "../assets/BGlv6.png";
import iconMETAZBlack from "../assets/metaz_logo.png";

const MAX_CLAIM_LEVEL = 6;

const claimUpgradeCosts = [
  0,
  5 * 10 ** 18,
  10 * 10 ** 18,
  15 * 10 ** 18,
  20 * 10 ** 18,
  50 * 10 ** 18,
];

interface PopupUpgradeClaimLevelProps {
  isShowPopup: boolean;
  onClose: () => void;
  currentClaimLevel: number;
  onUpgrade: () => void;
}

const PopupUpgradeClaimLevel: React.FC<PopupUpgradeClaimLevelProps> = ({
  isShowPopup,
  onClose,
  currentClaimLevel,
  onUpgrade,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isShowPopup) {
      setIsVisible(true);
    } else {
      const timer = setTimeout(() => setIsVisible(false), 300); // Match this with the transition duration
      return () => clearTimeout(timer);
    }
  }, [isShowPopup]);

  const claimIntervals = [2, 2, 4, 6, 8, 12, 24];
  const currentClaimInterval = claimIntervals[currentClaimLevel] || 2;
  const nextClaimInterval = claimIntervals[currentClaimLevel + 1] || 4;
  const isMaxLevel = currentClaimLevel >= MAX_CLAIM_LEVEL;
  const progressPercentage = (currentClaimLevel / MAX_CLAIM_LEVEL) * 100;

  const getImageBasedOnLevel = (level: number) => {
    switch (level) {
      case 1:
        return main_lv1;
      case 2:
        return main_lv2;
      case 3:
        return main_lv3;
      case 4:
        return main_lv4;
      case 5:
        return main_lv5;
      case 6:
        return main_lv6;
      default:
        return main_lv1;
    }
  };

  if (!isVisible) return null;

  return (
    <div
      className="fixed inset-0 z-50 bg-black bg-opacity-50 transition-opacity duration-300"
      style={{ opacity: isShowPopup ? 1 : 0 }}
    >
      <div
        className="fixed left-0 right-0 bottom-0 bg-white rounded-t-2xl p-4 transition-transform duration-300 ease-in-out transform"
        style={{
          transform: isShowPopup ? "translateY(0)" : "translateY(100%)",
        }}
      >
        <div className="text-3xl font-bold leading-6 text-center text-zinc-800 mb-6">
          Claim Level
        </div>
        <div className="text-base leading-6 text-center text-zinc-800 font-normal mb-6">
          {isMaxLevel
            ? "Maximum Level Reached"
            : "Increase Time To Claim Less Often"}
        </div>
        <div className="flex gap-4 justify-between mb-6">
          <div className="flex justify-center items-center h-20 w-20 border-2 border-[#3F2800] rounded-xl overflow-hidden">
            <img
              src={getImageBasedOnLevel(
                isMaxLevel ? MAX_CLAIM_LEVEL : currentClaimLevel + 1
              )}
              className="w-full h-full object-cover"
              alt={`Room Level ${
                isMaxLevel ? MAX_CLAIM_LEVEL : currentClaimLevel + 1
              }`}
            />
          </div>
          <div className="flex flex-col flex-1 justify-center">
            <div className="flex justify-between mb-2">
              <div>Level {currentClaimLevel}</div>
              <div>Claim Every {currentClaimInterval}h</div>
            </div>
            <div className="h-4 bg-zinc-300 rounded-full relative mb-2">
              <div
                className="h-full bg-[#FEBB56] rounded-full absolute left-0 top-0"
                style={{ width: `${progressPercentage}%` }}
              />
            </div>
            {!isMaxLevel && (
              <div className="flex justify-between">
                <div>Next Level</div>
                <div>Claim Every {nextClaimInterval}h</div>
              </div>
            )}
          </div>
        </div>
        <div className="flex gap-4">
          <button
            onClick={onClose}
            className="flex-1 py-4 border border-solid border-zinc-800 rounded-full text-zinc-800"
          >
            {isMaxLevel ? "Close" : "Cancel"}
          </button>
          {!isMaxLevel && (
            <button
              onClick={onUpgrade}
              className="flex-1 flex items-center justify-center py-4 text-[#252525] border border-solid shadow-2xl border-zinc-800 bg-[#FEBB56] rounded-full"
            >
              <img
                src={iconMETAZBlack}
                className="w-6 h-6 mr-2"
                alt="METAZ Icon"
              />
              <span>
                {ethers.formatEther(
                  BigInt(claimUpgradeCosts[currentClaimLevel])
                )}{" "}
                MTZ
              </span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PopupUpgradeClaimLevel;
