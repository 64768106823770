import React, { useEffect, useState } from "react";
import { Eye, Copy } from "lucide-react";
import { Button } from "../components/ui/button";
import { useToast } from "../components/hooks/use-toast";

type Props = {
  title: string;
  description: string;
  content: string;
  isShowDefault: boolean;
};

const BoxInputKey = ({ description, title, content, isShowDefault }: Props) => {
  const [isShow, setIsShow] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    setIsShow(isShowDefault);
  }, [isShowDefault]);

  const toggleVisibility = () => {
    setIsShow(!isShow);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(content);
    toast({
      title: "Copied to clipboard",
      description: `${title} has been copied to your clipboard.`,
    });
  };

  return (
    <div className="mt-4">
      <h3 className="font-bold leading-[137.5%]">{title}</h3>
      <p className="mt-1">{description}</p>
      <div className="flex flex-col justify-center p-4 mt-1 leading-6 rounded-2xl border border-solid shadow-sm border-zinc-800">
        <div className="relative">
          <div
            className={`break-all ${isShow ? "" : "blur-[5px] select-none"}`}
          >
            {content}
          </div>
          {!isShow && (
            <div className="absolute inset-0 flex items-center justify-center">
              <Button
                variant="outline"
                size="sm"
                onClick={toggleVisibility}
                className="flex items-center gap-2"
              >
                <Eye size={16} />
                Click to reveal
              </Button>
            </div>
          )}
        </div>
        <div className="flex justify-end mt-2.5">
          <Button
            variant="ghost"
            size="icon"
            onClick={copyToClipboard}
            title="Copy to clipboard"
          >
            <Copy size={16} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BoxInputKey;
