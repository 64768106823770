import axios from "axios";
import { AppDispatch, RootState } from "../redux/store";
import { setToken, clearToken } from "../redux/slices/jwtSlice";
import { BACKEND_URL } from "../components/constants/constant";

const API_URL = BACKEND_URL();

interface TelegramInitData {
  query_id: string;
  user: {
    id: number;
    first_name: string;
    last_name?: string;
    username?: string;
    language_code: string;
    allows_write_to_pm: boolean;
  };
  auth_date: string;
  hash: string;
  start_param?: string;
}

interface ReferralData {
  referrals: Array<{
    telegramId: string;
    walletAddress: string;
    firstName: string;
    lastName: string;
  }>;
  currentPage: number;
  totalPages: number;
  totalCount: number;
  limit: number;
}

export const sendTelegramInitData = async (
  telegramInitDataUnsafe: TelegramInitData,
  telegramInitData: string,
  dispatch: AppDispatch
): Promise<string> => {
  try {
    const response = await axios.post(`${API_URL}/api/auth`, {
      telegramInitDataUnsafe,
      telegramInitData,
    });
    const { token } = response.data;
    dispatch(setToken(token));
    return token;
  } catch (error) {
    console.error("Error sending Telegram init data:", error);
    throw error;
  }
};

export const linkAccount = async (
  walletAddress: string,
  token: string,
  referrerTelegramId?: string
) => {
  try {
    const response = await axios.post(
      `${API_URL}/api/link-account`,
      {
        walletAddress,
        referrerTelegramId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data.message === "Unauthorized"
    ) {
      throw new Error("Unauthorized");
    } else if (error.response && error.response.status === 400) {
      throw new Error(error.response.data.error);
    }
  }
};

export const isJwtValid = (token: string): boolean => {
  try {
    const payload = JSON.parse(atob(token.split(".")[1]));
    const expirationTime = payload.exp * 1000;
    return Date.now() < expirationTime;
  } catch (error) {
    console.error("Error validating JWT:", error);
    return false;
  }
};

export const initializeTelegramAuth = async (
  initDataUnsafe: TelegramInitData,
  initData: string,
  dispatch: AppDispatch,
  getState: () => RootState
): Promise<string | null> => {
  const token = getState().jwt.token;

  if (token && isJwtValid(token)) {
    return token;
  }

  try {
    const newToken = await sendTelegramInitData(
      initDataUnsafe,
      initData,
      dispatch
    );
    return newToken;
  } catch (error) {
    console.error("Error initializing Telegram auth:", error);
    dispatch(clearToken());
    return null;
  }
};

export const fetchReferralData = async (
  token: string,
  page: number = 1,
  limit: number = 10
): Promise<ReferralData> => {
  try {
    const response = await axios.get<ReferralData>(
      `${API_URL}/api/my-referrals`,
      {
        params: { page, limit },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data.message === "Unauthorized"
    ) {
      throw new Error("Unauthorized");
    } else {
      console.error("Error fetching referral data:", error);
      throw new Error("Failed to fetch referral data");
    }
  }
};

export const fetchMTZPendingRewards = async (token: string): Promise<any> => {
  try {
    const response = await axios.get(`${API_URL}/api/pending-rewards`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data.message === "Unauthorized"
    ) {
      throw new Error("Unauthorized");
    } else {
      console.error("Error fetching pending rewards:", error);
      throw new Error("Failed to fetch pending rewards");
    }
  }
};

export const claimMTZPendingRewards = async (token: string): Promise<any> => {
  try {
    const response = await axios.post(
      `${API_URL}/api/claim-signature`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data.message === "Unauthorized"
    ) {
      throw new Error("Unauthorized");
    } else {
      console.error("Error claiming pending rewards:", error);
      throw new Error("Failed to claim pending rewards");
    }
  }
};

export const fetchLeaderboardData = async (token: string): Promise<any> => {
  try {
    const response = await axios.get<any>(`${API_URL}/api/leaderboard`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data.message === "Unauthorized"
    ) {
      throw new Error("Unauthorized");
    } else {
      console.error("Error fetching referral data:", error);
      throw new Error("Failed to fetch referral data");
    }
  }
};

export const checkInApi = {
  dailyCheckIn: async (jwt: string) => {
    const response = await axios.post(
      `${API_URL}/api/tasks/daily-checkin`,
      {},
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
    return response.data;
  },

  getCheckInStatus: async (jwt: string) => {
    const response = await axios.get(`${API_URL}/api/tasks/checkin-status`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    return response.data;
  },
};

export const taskApi = {
  getTasks: async (jwt: string) => {
    const response = await axios.get(`${API_URL}/api/tasks/tasks`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    return response.data;
  },
  getCompletedTasks: async (jwt: string) => {
    const response = await axios.get(`${API_URL}/api/tasks/tasks/completed`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    return response.data;
  },
  startTask: async (jwt: string, taskId: string) => {
    const response = await axios.post(
      `${API_URL}/api/tasks/tasks/${taskId}/start`,
      {},
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
    return response.data;
  },
  checkTask: async (jwt: string, taskId: string) => {
    const response = await axios.post(
      `${API_URL}/api/tasks/tasks/${taskId}/check`,
      {},
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
    return response.data;
  },
  registerCoinExTask: async (jwt: string, email: string, _id: string) => {
    const type = _id.replace("coinex-", "");
    const response = await axios.post(
      `${API_URL}/api/tasks/coinex-task`,
      { email, type },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
    return response.data;
  },
  claimTask: async (jwt: string, taskId: string) => {
    const response = await axios.post(
      `${API_URL}/api/tasks/tasks/${taskId}/claim`,
      {},
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
    return response.data;
  },
};
