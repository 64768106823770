export const ABC = "11";
export const RPC_URL = () => {
  if (process.env.NODE_ENV === "development") {
    return "https://testnet-rpc.coinex.net";
  } else {
    return "https://rpc.coinex.net";
  }
};

export const CONTRACT_ADDRESS = () => {
  if (process.env.NODE_ENV === "development") {
    return "0x270508F3eE3a3F6060Ba46b9a97566465D08fd2f";
  } else {
    return "0x0e1e17bd1Af661DB21dA00DCc833AE10EDDBbB87";
  }
};

export const METAZ_TOKEN_ADDRESS = () => {
  if (process.env.NODE_ENV === "development") {
    return "0xb55990a4813ED267b2FEF02c04908Faf7b67FDF3";
  } else {
    // return "0x978C25c94Ea2cF39729BeE21D041b23f69e972Ac"; // real mtz
    return "0x978C25c94Ea2cF39729BeE21D041b23f69e972Ac"; // mtz
  }
};

export const MULTICALL3_ADDRESS = () => {
  if (process.env.NODE_ENV === "development") {
    return "0x1d3E8eA3c531d3d387956D3B64fEF52a3BdE278b";
  } else {
    return "0x0e5cF1c1E16716D5c5AF6ad49EB35221Fb026Ae1";
  }
};

export const BACKEND_URL = () => {
  if (process.env.NODE_ENV === "development") {
    return "http://localhost:4000";
  } else {
    return "https://game.metaz.finance";
  }
};

export const TELEGRAM_BOT_URL = () => {
  if (process.env.NODE_ENV === "development") {
    return "https://t.me/metaz_bot/start?startapp=";
  } else {
    return "https://t.me/MTZCat_bot/start?startapp=";
  }
};

// Base claim interval in seconds (2 hours)
export const BASE_CLAIM_INTERVAL = 2 * 60 * 60;

// Claim intervals for different levels (in seconds)
export const CLAIM_INTERVALS = [
  2 * 60 * 60, // 2 hours
  2 * 60 * 60, // 2 hours
  4 * 60 * 60, // 4 hours
  6 * 60 * 60, // 6 hours
  8 * 60 * 60, // 8 hours
  12 * 60 * 60, // 12 hours
  24 * 60 * 60, // 24 hours
];

// Reward amounts for different levels (in wei)
export const REWARD_AMOUNTS = [
  1e16, // 0.01 MTZ
  1e16, // 0.01 MTZ
  1e17, // 0.1 MTZ
  2e17, // 0.2 MTZ
  3e17, // 0.3 MTZ
  4e17, // 0.4 MTZ
  10e18, // 10 MTZ (CET amount for max level)
];

// Time to wait before next CET claim (in seconds)
export const CET_CLAIM_COOLDOWN = 7 * 24 * 60 * 60; // 7 days

export const MAX_CLAIM_LEVEL = CLAIM_INTERVALS.length - 1;
export const MAX_REWARD_LEVEL = REWARD_AMOUNTS.length - 1;

export enum GameState {
  IDLE = "idle",
  PLAYING = "playing",
  PAUSED = "paused",
  FINISHED = "finished",
}

export function getClaimInterval(level: number): number {
  return CLAIM_INTERVALS[Math.min(level, MAX_CLAIM_LEVEL)];
}

export function getRewardAmount(level: number): number {
  return REWARD_AMOUNTS[Math.min(level, MAX_REWARD_LEVEL)];
}
