import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUserData } from "../redux/slices/userSlices";
import DataContext from "../context/DataContext";
import { useToast } from "../components/hooks/use-toast";
import { Balance } from "../constants/constants";
import {
  extractEthereumAddress,
  formatBalance,
  formatDecimalString,
  transferNativeCoin,
  transferToken,
  triggerHapticFeedback,
} from "../utils/utils";

// Components
import Button from "../components/Button";
import CustomInput from "../components/Input";
import ChooseToken from "../components/ChooseToken";
import TransferResult from "../components/TransferResult";
import AmountInput from "../components/AmountInput";
import { Card, CardContent } from "..//components/ui/card";

// Assets
import { Camera, ChevronDown } from "lucide-react";
import icon_sui from "../assets/icon-sui.png";

interface FormData {
  receiver: string;
  token: Balance | null;
  amount: bigint;
}

type QrTextReceived = {
  type: "qr_text_received";
  data: string;
};

const Transfer: React.FC = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const dataUser = useSelector(getUserData);
  const { userTele, wallet, balanceToken, setButtonBackTele, getBalanceToken } =
    useContext(DataContext)!;

  const [isLoading, setIsLoading] = useState(false);
  const [isShowChoose, setIsShowChoose] = useState(false);
  const [tokenSelected, setTokenSelected] = useState<Balance | null>(null);
  const [perAmount, setPerAmount] = useState(50);
  const [balance, setBalance] = useState(BigInt(0));
  const [result, setResult] = useState<any>(null);
  const [formData, setFormData] = useState<FormData>({
    receiver: "",
    token: null,
    amount: BigInt(0),
  });

  useEffect(() => {
    if (!dataUser) {
      navigate("/");
    }
    setButtonBackTele(true, onCallBackButtonBack);
  }, [dataUser]);

  useEffect(() => {
    if (balanceToken && balanceToken.length > 0 && tokenSelected == null) {
      initializeToken(balanceToken[0]);
    }
  }, [balanceToken]);

  useEffect(() => {
    if (tokenSelected) {
      setFormData({ ...formData, token: tokenSelected });
    }
  }, [tokenSelected]);

  const initializeToken = (token: Balance) => {
    setTokenSelected(token);
    const bla = token.balance;
    const amount = calculateAmount(bla, BigInt(perAmount));
    setBalance(bla);
    setFormData({ ...formData, amount });
  };

  const calculateAmount = (balance: bigint, percentage: bigint): bigint => {
    return (balance * percentage) / BigInt(100);
  };

  const onCallBackButtonBack = () => navigate("/");

  const onChangeAmount = (per: number) => {
    setPerAmount(per);
    const amount = calculateAmount(balance, BigInt(per));
    setFormData({ ...formData, amount });
  };

  const handleScanQR = () => {
    if (window.Telegram?.WebApp?.showScanQrPopup) {
      window.Telegram.WebApp.showScanQrPopup({
        text: "Scan CoinEx Smart Chain Address QR Code",
      });
      window.Telegram.WebApp.onEvent("qrTextReceived", handleQRScanned);
    } else {
      toast({
        variant: "destructive",
        description: "QR scanner is not available in this environment.",
      });
    }
  };

  const handleQRScanned = (qrText: QrTextReceived) => {
    window.Telegram.WebApp.closeScanQrPopup();
    window.Telegram.WebApp.offEvent("qrTextReceived", handleQRScanned);

    // Validate the scanned text as an Ethereum address
    const extractedAddress = extractEthereumAddress(qrText.data);

    if (extractedAddress) {
      setFormData({ ...formData, receiver: extractedAddress });
    } else {
      toast({
        variant: "destructive",
        description: "Invalid CoinEx Smart Chain address QR code.",
      });
    }
  };

  const handleTransfer = async () => {
    triggerHapticFeedback("medium");
    if (!validateForm()) return;

    setIsLoading(true);
    const transferResult = await performTransfer();
    setResult(transferResult);
    setIsLoading(false);
    getBalanceToken();
  };

  const validateForm = (): boolean => {
    if (formData.receiver === "") {
      toast({
        variant: "destructive",
        description: "Please fill receiver fields",
      });
      return false;
    }
    if (!formData.token) {
      toast({
        variant: "destructive",
        description: "Please fill asset fields",
      });
      return false;
    }
    if (formData.amount === BigInt(0)) {
      toast({
        variant: "destructive",
        description: "Please fill amount fields",
      });
      return false;
    }
    return true;
  };

  const performTransfer = async (): Promise<any> => {
    if (!formData.token) return null;
    return formData.token.isNative
      ? await transferNativeCoin(
          wallet.privateKey,
          formData.receiver,
          formData.amount,
          formData.token
        )
      : await transferToken(
          wallet.privateKey,
          formData.receiver,
          formData.amount,
          formData.token
        );
  };

  const onClickItemToken = (data: Balance) => {
    triggerHapticFeedback("medium");
    setIsShowChoose(false);
    initializeToken(data);
  };

  const onShowSelectToken = () => {
    triggerHapticFeedback("medium");
    setIsShowChoose(!isShowChoose);
  };

  const onGoHomeFromResult = () => {
    triggerHapticFeedback("medium");
    setResult(null);
  };

  if (!wallet) return null;
  if (result)
    return <TransferResult result={result} onClose={onGoHomeFromResult} />;

  return (
    <div className="flex flex-col pb-20 mx-auto w-full text-base leading-6 bg-gray-100 min-h-screen">
      <Card className="m-4 shadow-lg">
        <CardContent className="p-6">
          <h1 className="text-3xl font-black text-zinc-800 mb-2">Transfer</h1>
          <p className="text-gray-600 mb-6">Send token in an instant</p>

          <ReceiverInput
            value={formData.receiver}
            onChange={(value) => setFormData({ ...formData, receiver: value })}
            onScanQR={handleScanQR}
          />

          <AmountSection
            balance={balance}
            formData={formData}
            setFormData={setFormData}
            tokenSelected={tokenSelected}
            onShowSelectToken={onShowSelectToken}
          />

          <PercentageSelector
            perAmount={perAmount}
            onChangeAmount={onChangeAmount}
          />

          <Button
            className="w-full mt-6"
            isLoading={isLoading}
            label="Transfer"
            handleClick={handleTransfer}
          />
        </CardContent>
      </Card>

      <ChooseToken
        isShowChoose={isShowChoose}
        onClickItemToken={onClickItemToken}
        onShowSelectToken={onShowSelectToken}
      />
    </div>
  );
};

const ReceiverInput: React.FC<{
  value: string;
  onChange: (value: string) => void;
  onScanQR: () => void;
}> = ({ value, onChange, onScanQR }) => (
  <div className="mb-6">
    <div className="font-bold text-zinc-800 mb-2">Receiver</div>
    <Card className="shadow-md">
      <CardContent className="p-3">
        <div className="flex gap-2.5 justify-between items-center">
          <CustomInput
            type="text"
            className="flex-grow"
            placeholder="Enter Address"
            value={value}
            onChange={(e) => onChange(e.target.value)}
          />
          <Camera
            className="text-gray-500 cursor-pointer"
            size={24}
            onClick={onScanQR}
          />
        </div>
      </CardContent>
    </Card>
  </div>
);

const AmountSection: React.FC<{
  balance: bigint;
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  tokenSelected: Balance | null;
  onShowSelectToken: () => void;
}> = ({ balance, formData, setFormData, tokenSelected, onShowSelectToken }) => (
  <div className="mb-6">
    <div className="flex justify-between mb-2">
      <div className="font-bold text-zinc-800">Amount</div>
      <div className="text-gray-500">
        Balance: {formatDecimalString(formatBalance(balance), 4)}
      </div>
    </div>
    <div className="flex gap-2 items-center">
      <Card className="flex-grow shadow-md">
        <CardContent className="p-3">
          <AmountInput
            formData={formData}
            setFormData={setFormData}
            tokenSelected={tokenSelected}
            onAmountChange={(newAmount) =>
              setFormData({ ...formData, amount: newAmount })
            }
          />
        </CardContent>
      </Card>
      {tokenSelected && (
        <Card className="shadow-md cursor-pointer" onClick={onShowSelectToken}>
          <CardContent className="p-3 flex items-center gap-2">
            <img
              src={tokenSelected.iconUrl || icon_sui}
              className="w-6 h-6"
              alt={`${tokenSelected.symbol} icon`}
            />
            <span className="font-semibold">{tokenSelected.symbol}</span>
            <ChevronDown size={16} className="text-gray-500" />
          </CardContent>
        </Card>
      )}
    </div>
  </div>
);

const PercentageSelector: React.FC<{
  perAmount: number;
  onChangeAmount: (amount: number) => void;
}> = ({ perAmount, onChangeAmount }) => (
  <Card className="shadow-md mb-6">
    <CardContent className="p-1">
      <div className="flex justify-between">
        {[25, 50, 75, 100].map((amount) => (
          <div
            key={amount}
            className={`flex-1 py-2 text-center cursor-pointer transition-colors duration-200 ${
              perAmount === amount
                ? "bg-[#FEBB56] text-white rounded-full"
                : "text-gray-500 hover:bg-gray-100 rounded-full"
            }`}
            onClick={() => onChangeAmount(amount)}
          >
            {amount === 100 ? "MAX" : `${amount}%`}
          </div>
        ))}
      </div>
    </CardContent>
  </Card>
);

export default Transfer;
